import { Error } from '@/types/Error';
let defaultMode;
export type ThemeModes = 'dark' | 'light' | 'notset';
if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
  defaultMode = <ThemeModes>'dark'
} else {
  defaultMode = <ThemeModes>'light'
}

export type AppState = {
  mode: ThemeModes,
  dirt: boolean,
  dirtyShowResolve: (vale?:any) => void,
  dirtyShowReject: (vale?:any) => void,
  dirtModal: boolean,
  errors: Error[],
  apiVersion: string
}

export const state: AppState = {
  mode: defaultMode,
  dirt: false,
  dirtyShowResolve: () => {},
  dirtyShowReject: () => {},
  dirtModal: false,
  errors: [],
  apiVersion: ''
};

const _state = JSON.parse(JSON.stringify(state));
export const defaultState = (): AppState => {
  {
    return JSON.parse(JSON.stringify(_state))
  }
};
