export enum ServiceCatalogueActionTypes {

  GETSERVICECATALOGUEITEMS_ACTION = 'GetServiceCatalogueItems',

  GETSERVICECATALOGUEITEM_ACTION = 'GetServiceCatalogueItem',
  SETSERVICECATALOGUEITEM_ACTION = 'SetServiceCatalogueItem',
  SAVESERVICECATALOGUEITEM_ACTION = 'SaveServiceCatalogueItem',
  GET_SERVICECATALOGUE_CATEGORIES_ACTION = "GetServiceCatalogueCategories",
  SAVE_SERVICECATALOGUE_CATEGORIES_ACTION = "SaveServiceCatalogueCategories",

  GET_SERVICECATALOGUE_PRIORITIES_ACTION = "GetServiceCataloguePriorities",
  SAVE_SERVICECATALOGUE_PRIORITIES_ACTION = "SaveServiceCataloguePriorities",

  SAVE_SERVICECATALOGUE_CATEGORY_ACTION = "SaveServiceCatalogueCategory",
  DELETE_SERVICECATALOGUE_CATEGORY_ACTION = "DeleteServiceCatalogueCategory",

  SAVE_SERVICECATALOGUE_PRIORITY_ACTION = "SaveServiceCataloguePriority",
  DELETE_SERVICECATALOGUE_PRIORITY_ACTION = "DeleteServiceCataloguePriority",

  SAVE_SERVICECATALOGUE_CATEGORY_TREE_ACTION = "SaveServiceCatalogueCategoryTree",

  DELETESERVICECATALOGUEITEM_ACTION = 'DeleteServiceCatalogueItem',

  SET_SERVICECATALOGUEITEM_TO_PREVIEW_MOD_ACTION = 'SetServiceCatalogueItemToPreviewModAction',
  SET_SERVICECATALOGUEITEM_PREVIEW_OPEN_STATE_ACTION = 'SetServiceCatalogueItemPreviewOpenStateAction',

  SET_PAGE = 'SetPage',
  SET_PAGESIZE = 'SetPageSize',
  SET_TOTAL = 'SetTotal',
  SET_SEARCH = 'SetSearch',
  SET_FILTER = 'SetFilter',
  SET_AVAILABLEFIELDS = 'SetAvailableFields',
  SET_SORTS = 'SetSorts'
}
