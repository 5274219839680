import { Organisation } from './Organisation'
import { Permission } from './Permission'
import { Group } from './Group'
import { StripeSubscription } from './StripeSubscription'
// import { FilterOptionDef } from './FilterDef'
// userAccess
export type UserAccess = 'client' | 'admin';

const OUserImportMapping = {
  email: {
    userField: "email",
    csvField: "",
    mapped: false,
  },
  firstName: {
    userField: "firstName",
    csvField: "",
    mapped: false,
  },
  lastName: {
    userField: "lastName",
    csvField: "",
    mapped: false,
  },
  role: {
    userField: "role",
    csvField: "",
    mapped: false,
  },
  mobilePhone: {
    userField: "mobilePhone",
    csvField: "",
    mapped: false,
  },
  officePhone: {
    userField: "officePhone",
    csvField: "",
    mapped: false,
  },
  addOrInvite: { //add_only, add_and_invite
    userField: "addOrInvite",
    csvField: "",
    mapped: false,
  },
};
// type UserImportMapping = keyof typeof OUserImportMapping;
type UserImportMapping = typeof OUserImportMapping;

const OUserImportStage = {
  upload: "upload",
  mapping: "mapping",
  preview: "preview",
  progress: "progress",
  finish: "finish",
} as const;
type UserImportStage = keyof typeof OUserImportStage;

class User {
  _id: number | string | readonly string[];
  userName: string;
  password: string;
  email: string;
  firstName: string;
  lastName: string;
  groups: Array<Group>;
  organisation: Organisation | null;
  subscription: StripeSubscription | number | string | readonly string[] | null;
  role: string;
  avatar: string;
  avatarUrl: string;
  access: Permission[];
  mobilePhonePrefix: number | null;
  mobilePhone: number | null;
  officePhonePrefix: number | null;
  officePhone: number | null;
  isActive: boolean;
  isEnabled: boolean;
  passwordUpdateRequired: boolean;
  createdAt: Date | null;
  updatedAt: Date | null;

  constructor(
    _id: number | string | readonly string[],
    userName: string,
    password: string,
    email: string,
    firstName: string,
    lastName: string,
    groups: Array<Group>,
    organisation: Organisation | null,
    subscription: StripeSubscription | number | string | readonly string[] | null,
    role: string,
    avatar: string,
    avatarUrl: string,
    access: Permission[],
    mobilePhonePrefix: number | null,
    mobilePhone: number | null,
    officePhonePrefix: number | null,
    officePhone: number | null,

    isActive: boolean,
    isEnabled: boolean,
    passwordUpdateRequired: boolean,
    createdAt: Date | null,
    updatedAt: Date | null

  ) {
    this._id = _id
    this.userName = userName
    this.password = password
    this.email = email
    this.firstName = firstName
    this.lastName = lastName
    this.groups = groups ? groups.map(group => Group.fromObject(group)) : []
    this.organisation = organisation ? Organisation.fromObject(organisation) : null
    this.subscription = ( subscription !== null && typeof subscription == 'object' ) ? StripeSubscription.fromObject(subscription) : ( subscription === null ) ? null : subscription,
    this.role = role
    this.avatar = avatar
    this.avatarUrl = avatarUrl
    this.access = access
    this.mobilePhonePrefix = mobilePhonePrefix
    this.mobilePhone = mobilePhone
    this.officePhonePrefix = officePhonePrefix
    this.officePhone = officePhone

    this.isActive = isActive
    this.isEnabled = isEnabled
    this.passwordUpdateRequired = passwordUpdateRequired
    this.createdAt = createdAt
    this.updatedAt = updatedAt
  }

  public fullName(): string {
    return `${this.firstName} ${this.lastName}`
  }

  static fromObject(obj: any): User {
    return new User(
      obj._id,
      obj.userName,
      obj.password,
      obj.email,
      obj.firstName,
      obj.lastName,
      obj.groups,
      obj.organisation,
      obj.subscription,
      obj.role,
      obj.avatar,
      obj.avatarUrl,
      obj.access,
      obj.mobilePhonePrefix,
      obj.mobilePhone,
      obj.officePhonePrefix,
      obj.officePhone,
      obj.isActive,
      obj.isEnabled,
      obj.passwordUpdateRequired,
      obj.createdAt,
      obj.updatedAt,
    )
  }

  static getDefaultObject(): User {
    return User.fromObject({
      _id: -1,
      userName: '',
      password: '',
      email: '',
      firstName: '',
      lastName: '',
      groups: [],
      organisation: {},
      subscription: null,
      role: 'User',
      avatar: '',
      avatarUrl: '',
      access: '',
      mobilePhonePrefix: null,
      mobilePhone: null,
      officePhonePrefix: null,
      officePhone: null,

      isActive: false,
      isEnabled: false,
      passwordUpdateRequired: false,
      createdAt: null,
      updatedAt: null,
    })
  }

  // toFilterOptionDef(user:User):FilterOptionDef[]{
  //   return FilterOptionDef.fromObject({
  //     id: user._id;
  //     name: `user.fullName`;
  //     // key: OptionsType | OptionsTypeTicket;
  //     key: string;
  //   });
  // }

}

class UserDTO {
  _id: number | string | readonly string[];
  userName: string;
  email: string;
  firstName: string;
  lastName: string;
  groups: Array<string>;
  organisation: string;
  role: string;
  avatar: string;
  avatarUrl: string;
  constructor(
    _id: number | string | readonly string[],
    userName: string,
    email: string,
    firstName: string,
    lastName: string,
    groups: Array<string>,
    organisation: string,
    role: string,
    avatar: string,
    avatarUrl: string
  ) {
    this._id = _id
    this.userName = userName
    this.email = email
    this.firstName = firstName
    this.lastName = lastName
    this.groups = groups, //groups ? groups.map(group => Group.fromObject(group)) : []
      this.organisation = organisation, //Organisation.fromObject(organisation)
      this.role = role
    this.avatar = avatar
    this.avatarUrl = avatarUrl
  }

  public fullName(): string {
    return `${this.firstName} ${this.lastName}`
  }

  static fromObject(obj: any): UserDTO {
    return new UserDTO(
      obj._id,
      obj.userName,
      obj.email,
      obj.firstName,
      obj.lastName,
      obj.groups,
      obj.organisation,
      obj.role,
      obj.avatar,
      obj.avatarUrl
    )
  }

  static getDefaultObject(): UserDTO {
    return UserDTO.fromObject({
      _id: -1,
      userName: 'User Name',
      email: 'email',
      firstName: 'First Name',
      lastName: 'Last Name',
      groups: [],
      organisation: {},
      role: 'Role',
      avatar: '#',
      avatarUrl: '/#'
    })
  }
}

class UserImport {
  _id: number | string | readonly string[];
  fileName: string;
  path: string;
  stage: UserImportStage | null;
  mapping: UserImportMapping;
  owner: string;
  csvHeaders: string[];
  csvData: any;

  constructor(
      _id: number | string | readonly string[],
      fileName: string,
      path: string,
      stage: UserImportStage | null,
      mapping: UserImportMapping,
      owner: string,
      csvHeaders: string[],
      csvData: any
  ) {
    this._id = _id;
    this.fileName = fileName;
    this.path = path;
    this.stage = stage;
    this.mapping = mapping;
    this.owner = owner;
    this.csvHeaders = csvHeaders;
    this.csvData = csvData;
  }

  static fromObject(obj: any): UserImport {
    return new UserImport(
        obj._id,
        obj.fileName,
        obj.path,
        obj.stage,
        obj.mapping,
        obj.owner,
        obj.csvHeaders,
        obj.csvData
    );
  }
}

class UserExport {
  name: string;
  time: number;
  size: number;

  constructor(
      name: string,
      time: number,
      size: number
  ) {
    this.name = name;
    this.time = time;
    this.size = size;
  }

  static fromObject(obj: any): UserExport {
    return new UserExport(
        obj.name,
        obj.time,
        obj.size
    );
  }
}

export {
  User,
  UserDTO,
  UserImport,
  UserImportMapping,
  OUserImportMapping,
  UserExport
}
