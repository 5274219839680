export enum UserActionTypes {
  LOAD_USERS_ACTION = 'LoadUsers',

  USER_IMPORT_FILEUPLOAD = "UserImportFileUpload",
  USER_IMPORT_DO_IMPORT = "UserImportDoImport",

  USER_EXPORT_GENERATE = "UserGenerateExport",
  USER_LIST_EXPORTS = "UserListExports",
  USER_DELETE_EXPORT = "UserDeleteExport",
  USER_DOWNLOAD_EXPORT = "UserDownloadExport",
}
