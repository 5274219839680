// import { omit } from 'lodash';
import { ActionContext, ActionTree } from "vuex";
import { RootState } from "@/store";
import { State } from "./state";
import { UserActionTypes } from "./action-types";
// import axios, { AxiosResponse, AxiosError } from 'axios';
import { Config } from "@/config";
import { Mutations } from "./mutations";
import { UserMutationTypes } from "./mutation-types";
import {HttpClient as http, AxiosError, AxiosResponse} from "@/util/ZebritAxios";
import {UserImport, UserExport} from "@/types";
// import { User } from "@/types/index";
// import { LoginBody, LoginResponse } from "@/types/LoginTypes";
// import { AuthActionTypes } from "@/store/modules/auth/action-types";
// import { UserMutationTypes } from "@/store/modules/auth/mutation-types";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [UserActionTypes.LOAD_USERS_ACTION]({ commit }: AugmentedActionContext): void;

    [UserActionTypes.USER_IMPORT_FILEUPLOAD](
        { commit }: AugmentedActionContext,
        payload: File
    ): void;
    [UserActionTypes.USER_IMPORT_DO_IMPORT](
        { commit }: AugmentedActionContext,
        payload: UserImport
    ): void;

  [UserActionTypes.USER_EXPORT_GENERATE](
      { commit }: AugmentedActionContext
  ): void;

  [UserActionTypes.USER_LIST_EXPORTS](
      { commit }: AugmentedActionContext
  ): void;

  // [UserActionTypes.USER_DELETE_EXPORT](
  //   { commit }: AugmentedActionContext,
  //   payload: UserExport
  // ): void;

  [UserActionTypes.USER_DOWNLOAD_EXPORT](
      { commit }: AugmentedActionContext,
      payload: UserExport
  ): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [UserActionTypes.LOAD_USERS_ACTION]({ commit }) {
    return new Promise(function (resolve, reject) {
      http
        .get(Config.api.user.users)
        .then((response: any) => {
          if (response.isAxiosError || response.data.error) {
            commit(UserMutationTypes.SET_IS_LOADING_USERS, false);
            if (response.data) {
              // commit(UserMutationTypes.SET_LOGIN_ERROR, response.data.error);
              // commit(UserMutationTypes.SET_LOGIN_ERROR_MESSAGE, response.data.message);
            } else if (response.isAxiosError) {
              // commit(UserMutationTypes.SET_LOGIN_ERROR, 0);
              // commit(UserMutationTypes.SET_LOGIN_ERROR_MESSAGE, response.message);
            }
            commit(UserMutationTypes.SET_USERS, []);
            resolve(response);
          } else {
            commit(UserMutationTypes.SET_USERS, response.data);
            resolve(response);
          }
        })
        .catch((err:AxiosError) => {
          console.log(err);
          reject(err);
        });
    });
  },

    async [UserActionTypes.USER_IMPORT_FILEUPLOAD](
        { commit }: AugmentedActionContext,
        payload: File
    ) {
        const data = new FormData();
        // const file = payload.files[0];
        data.append("name", payload.name);
        data.append("file", payload);
        // data.append("userId", this.user._id);
        return new Promise((resolve, reject) => {
            // debugger; // eslint-disable-line
            http
                .post(Config.file.userFileUploadImportCSV, data, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then(
                    (response: AxiosResponse) => {
                        commit(UserMutationTypes.SET_USER_IMPORT, response.data as UserImport);
                        resolve();
                    },
                    (error: AxiosError) => {
                        reject();
                    }
                );
        });
    },

    async [UserActionTypes.USER_IMPORT_DO_IMPORT](
        { commit }: AugmentedActionContext,
        payload: UserImport
    ) {
        commit(UserMutationTypes.SET_USER_IMPORT_IS_IMPORTING, true);
        return new Promise((resolve, reject) => {
            debugger; // eslint-disable-line
            http
                .put(Config.api.user.userImportDoImport, {
                    _id: payload._id,
                    mapping: payload.mapping,
                })
                .then(
                    (response: AxiosResponse) => {
                        commit(UserMutationTypes.SET_USER_IMPORT_IS_IMPORTING, false);
                        commit(UserMutationTypes.SET_USER_IMPORT_DONE, true);
                        commit(UserMutationTypes.SET_USER_IMPORT_RESULTS, response.data);
                        debugger; // eslint-disable-line
                        //commit(UserMutationTypes.SET_USER_IMPORT, response.data as UserImport)
                        resolve();
                    },
                    (error: AxiosError) => {
                        commit(UserMutationTypes.SET_USER_IMPORT_IS_IMPORTING, false);
                        commit(UserMutationTypes.SET_USER_IMPORT_DONE, true);
                        commit(UserMutationTypes.SET_USER_IMPORT_RESULTS, null);
                        reject();
                    }
                );
        });
    },

  async [UserActionTypes.USER_EXPORT_GENERATE](
      { commit }: AugmentedActionContext
  ) {
    commit(UserMutationTypes.SET_USER_EXPORT_IS_GENERATING, true);
    commit(UserMutationTypes.SET_USER_EXPORT_GENERATE_DONE, false);
    return new Promise((resolve, reject) => {
      // debugger; // eslint-disable-line
      http
          .post(Config.api.user.userExportGenerate, {})
          .then(
              (response: AxiosResponse) => {
                commit(UserMutationTypes.SET_USER_EXPORT_IS_GENERATING, false);
                commit(UserMutationTypes.SET_USER_EXPORT_GENERATE_DONE, true);
                commit(UserMutationTypes.SET_USER_EXPORT_GENERATE_RESULTS, response.data);
                // debugger; // eslint-disable-line
                //commit(UserMutationTypes.SET_USER_IMPORT, response.data as UserImport)
                resolve();
              },
              (error: AxiosError) => {
                commit(UserMutationTypes.SET_USER_EXPORT_IS_GENERATING, false);
                commit(UserMutationTypes.SET_USER_EXPORT_GENERATE_DONE, false);
                commit(UserMutationTypes.SET_USER_EXPORT_GENERATE_RESULTS, null);
                reject();
              }
          );
    });
  },

  async [UserActionTypes.USER_LIST_EXPORTS](
      { commit }: AugmentedActionContext
  ) {
    commit(UserMutationTypes.SET_USER_EXPORTS_IS_LOADING, true);
    commit(UserMutationTypes.SET_USER_EXPORTS_LEADED, false);
    return new Promise((resolve, reject) => {
      // debugger; // eslint-disable-line
      http
          .get(Config.api.user.userExportList)
          .then(
              (response: AxiosResponse) => {
                commit(UserMutationTypes.SET_USER_EXPORTS_IS_LOADING, false);
                commit(UserMutationTypes.SET_USER_EXPORTS_LEADED, true);
                commit(UserMutationTypes.SET_USER_EXPORTS, response.data);
                // debugger; // eslint-disable-line
                //commit(UserMutationTypes.SET_USER_IMPORT, response.data as UserImport)
                resolve();
              },
              (error: AxiosError) => {
                commit(UserMutationTypes.SET_USER_EXPORTS_IS_LOADING, false);
                commit(UserMutationTypes.SET_USER_EXPORTS_LEADED, false);
                commit(UserMutationTypes.SET_USER_EXPORTS, []);
                reject();
              }
          );
    });
  },

  async [UserActionTypes.USER_DELETE_EXPORT](
      { commit }: AugmentedActionContext
  ) {
    commit(UserMutationTypes.SET_USER_EXPORT_IS_GENERATING, true);
    return new Promise((resolve, reject) => {
      // debugger; // eslint-disable-line
      // http
      //     .post(Config.api.user.userExportGenerate, {})
      //     .then(
      //         (response: AxiosResponse) => {
      //           commit(UserMutationTypes.SET_USER_EXPORT_IS_GENERATING, false);
      //           commit(UserMutationTypes.SET_USER_EXPORT_GENERATE_DONE, true);
      //           commit(UserMutationTypes.SET_USER_EXPORT_GENERATE_RESULTS, response.data);
      //           // debugger; // eslint-disable-line
      //           //commit(UserMutationTypes.SET_USER_IMPORT, response.data as UserImport)
      //           resolve(response.data);
      //         },
      //         (error: AxiosError) => {
      //           commit(UserMutationTypes.SET_USER_EXPORT_IS_GENERATING, false);
      //           commit(UserMutationTypes.SET_USER_EXPORT_GENERATE_DONE, true);
      //           commit(UserMutationTypes.SET_USER_EXPORT_GENERATE_RESULTS, null);
      //           reject();
      //         }
      //     );
    });
  },

  async [UserActionTypes.USER_DOWNLOAD_EXPORT](
      { commit }: AugmentedActionContext,
      payload: UserExport
  ) {
    commit(UserMutationTypes.SET_USER_EXPORT_IS_GENERATING, true);
    const { name } = payload;
    return new Promise((resolve, reject) => {
      debugger; // eslint-disable-line
      // http
      //     .get(Config.api.user.userExportDownload.replace('{file}', name), {})
      //     .then(
      //         (response: AxiosResponse) => {
      //           commit(UserMutationTypes.SET_USER_EXPORT_IS_GENERATING, false);
      //           commit(UserMutationTypes.SET_USER_EXPORT_GENERATE_DONE, true);
      //           commit(UserMutationTypes.SET_USER_EXPORT_GENERATE_RESULTS, response.data);
      //           debugger; // eslint-disable-line
      //           //commit(UserMutationTypes.SET_USER_IMPORT, response.data as UserImport)
      //           resolve(response.data);
      //         },
      //         (error: AxiosError) => {
      //           commit(UserMutationTypes.SET_USER_EXPORT_IS_GENERATING, false);
      //           commit(UserMutationTypes.SET_USER_EXPORT_GENERATE_DONE, true);
      //           commit(UserMutationTypes.SET_USER_EXPORT_GENERATE_RESULTS, null);
      //           reject();
      //         }
      //     );
    });
  },

};
