import {FilterDef, Organisation, Permission, PermissionKeys, Role, SortDef, User,} from "@/types/index";
import {store} from "@/store";
import {OrganisationActionTypes} from "@/store/modules/organisation/action-types";
import {computed, reactive} from "vue";
import AuthService from "@/services/auth.service";

interface IuserRolesData {
  userLoadCalled: boolean;
  rolesLoadCalled: boolean;
  user: User | null;
  organisation: Organisation | null;
  users: User[];
  usersSorts: SortDef[];
  usersFilter: FilterDef[];
  roles: Role[];
  permissions: Record<PermissionKeys, Permission> | null;
  userRole: Role | null;
  userLoadPromisse: Promise<any> | null;
  organisasionLoadPromisse: Promise<any> | null;
  usersLoadPromisse: Promise<any> | null;
  rolesLoadPromisse: Promise<any> | null;
  permissionsLoadPromisse: Promise<any> | null;
  usersLoaded: boolean;
}

const userRolesData: IuserRolesData = reactive({
  userLoadCalled: false,
  rolesLoadCalled: false,
  usersLoaded: false,
  user: computed(() => {
    return store.state.auth?.user as User | null;
  }),
  organisation: computed(() => {
    return store.state.organisation?.organisation as Organisation | null;
  }),
  users: computed(() => {
    return store.state.organisation?.users as User[];
  }),
  usersSorts: computed(() => {
    return store.state.organisation?.usersSorts as SortDef[];
  }),
  usersFilter: computed(() => {
    return store.state.organisation?.usersFilter as FilterDef[];
  }),
  roles: computed(() => {
    return store.state.organisation?.roles as Role[];
  }),
  permissions: computed(() => {
    return store.state.organisation?.permissions as Record<
      PermissionKeys,
      Permission
    > | null;
  }),
  userRole: computed(() => {
    //
    return userRolesData.user && userRolesData.user.role
      ?
      (userRolesData.roles.find((r) => r._id == userRolesData?.user?.role) as Role)
      : null;
  }),
  userLoadPromisse: null,
  organisasionLoadPromisse: null,
  usersLoadPromisse: null,
  rolesLoadPromisse: null,
  permissionsLoadPromisse: null
});

export function userRoles(force = false) {
  if (force || !userRolesData.userLoadCalled || !userRolesData.rolesLoadCalled) {
    userRolesData.userLoadCalled = true;
    userRolesData.rolesLoadCalled = true;
    if (force) {
      userRolesData.usersLoaded = false;
      userRolesData.userLoadPromisse = AuthService.loadCurrentUser();

      userRolesData
        .userLoadPromisse!.then(() => {
          userRolesData.organisasionLoadPromisse = userRolesData.user?.organisation?._id
            ? store.dispatch(
              "organisation/" + OrganisationActionTypes.LOAD_ORGANIZATION_ACTION,
              userRolesData.user?.organisation?._id
            )
            : null;

          userRolesData.organisasionLoadPromisse?.then(() => {

            userRolesData.usersLoadPromisse = userRolesData.organisasionLoadPromisse
              ? store.dispatch("organisation/" + OrganisationActionTypes.LOAD_USERS_ACTION)
              : null;

            userRolesData.rolesLoadPromisse = userRolesData.organisasionLoadPromisse
              ? store.dispatch("organisation/" + OrganisationActionTypes.LOAD_ROLES_ACTION)
              : null;

            userRolesData.permissionsLoadPromisse = userRolesData.organisasionLoadPromisse
              ? store.dispatch("organisation/" + OrganisationActionTypes.LOAD_PERMISSIONS_ACTION)
              : null;

          })

        })
        .catch((error) => {
          console.warn(error);
        });

    } else {
      userRolesData.userLoadPromisse =
        userRolesData.userLoadPromisse || AuthService.loadCurrentUser();

      userRolesData
        .userLoadPromisse!.then(() => {
          userRolesData.organisasionLoadPromisse =
            userRolesData.organisasionLoadPromisse ||
              userRolesData.user?.organisation?._id
              ? store.dispatch(
                "organisation/" + OrganisationActionTypes.LOAD_ORGANIZATION_ACTION,
                userRolesData.user?.organisation?._id
              )
              : null;

          userRolesData.organisasionLoadPromisse?.then(() => {

            userRolesData.usersLoadPromisse =
              userRolesData.usersLoadPromisse || userRolesData.organisasionLoadPromisse
                ? store.dispatch(
                  "organisation/" + OrganisationActionTypes.LOAD_USERS_ACTION
                )
                : null;

            userRolesData.rolesLoadPromisse =
              userRolesData.rolesLoadPromisse || userRolesData.organisasionLoadPromisse
                ? store.dispatch(
                  "organisation/" + OrganisationActionTypes.LOAD_ROLES_ACTION
                )
                : null;
            userRolesData.permissionsLoadPromisse =
              userRolesData.permissionsLoadPromisse ||
                userRolesData.organisasionLoadPromisse
                ? store.dispatch(
                  "organisation/" + OrganisationActionTypes.LOAD_PERMISSIONS_ACTION
                )
                : null;

          })

        })
        .catch((error) => {
          console.warn(error);
        });
    }
  }

  userRolesData.usersLoadPromisse
    ?.then(() => {
      console.log('usersLoaded')
      userRolesData.usersLoaded = true;
    })
    .catch((error) => {
      console.warn(error);
    });

  return userRolesData;
}

Promise.all([
  userRolesData.userLoadPromisse,
  userRolesData.organisasionLoadPromisse,
  userRolesData.rolesLoadPromisse,
  userRolesData.permissionsLoadPromisse,
])
  .then(function () {
    userRolesData;
    userRolesData.userRole;
  })
  .catch((error) => {
    console.warn(error);
  });
export function hasPermission(permissions: PermissionKeys[] | null) {

  if (permissions === null)
    return true;

  let hasPermission = false;
  for (let i = 0; i < permissions!.length; i++) {
    hasPermission = hasPermission || hasSinglePermission(permissions[i] as (PermissionKeys | null))
  }
  return hasPermission;
}
function hasSinglePermission(permission: PermissionKeys | null) {

  const userScope = store.state.auth.scope;
  const permissions = [permission as string];

  let forbidden = !!permissions.filter(
    (permission: string) => userScope.indexOf("-" + permission) > -1
  )[0];

  const requiredPermissions = permissions.filter(
    (permission: string) => permission[0] === "+"
  );

  let hasRequired = true;
  if (requiredPermissions[0]) {
    hasRequired = !!userScope.filter(
      (scope) => requiredPermissions.indexOf("+" + scope) > -1
    )[0];
  }

  let hasPermission = !!userScope.filter((scope) => permissions.indexOf(scope) > -1)[0];

  // Root users always have access
  if (userScope.indexOf("root") > -1) {
    hasPermission = true;
    forbidden = false;
    hasRequired = true;
  }

  // Restrict access if the user does not have permission
  // console.log("permission: " + permission + " ret: " + ret);
  return !(!hasPermission || !hasRequired || forbidden);
}
