import {
  ActionContext,
  ActionTree
} from 'vuex';
import { RootState } from '@/store';
import { AppActionTypes } from './action-types';
import { AppState as State, ThemeModes } from './state';
import { Mutations } from './mutations';
import { AppMutationTypes } from './mutation-types';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [AppActionTypes.GETMODE_ACTION](
    { commit }: AugmentedActionContext
  ): ThemeModes;

  [AppActionTypes.SETMODE_ACTION](
    { commit }: AugmentedActionContext,
    payload: ThemeModes
  ): void;

  [AppActionTypes.GET_API_VERSION_ACTION](
    { commit }: AugmentedActionContext
  ): string;

  [AppActionTypes.SET_API_VERSION_ACTION](
    { commit }: AugmentedActionContext,
    payload: string
  ): void;

  [AppActionTypes.SET_DIRT_ACTION](
      { commit }: AugmentedActionContext,
      payload: boolean
  ): void;
  [AppActionTypes.DIRT_CONFIRM_STAY_ACTION](
      { commit, getters, dispatch }: AugmentedActionContext,
  ): void;
  [AppActionTypes.DIRT_STAY_RESOLVE_ACTION](
      { commit }: AugmentedActionContext,
  ): void;
  [AppActionTypes.DIRT_STAY_REJECT_ACTION](
      { commit }: AugmentedActionContext,
  ): void;

}

export const actions: ActionTree<State, RootState> & Actions = {

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  [AppActionTypes.GETMODE_ACTION](
    { commit }: AugmentedActionContext
  ) {

    let mode;
    if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
      mode = <ThemeModes>'dark'
    } else {
      mode = <ThemeModes>'light'
    }
    return mode;

  },

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  [AppActionTypes.SETMODE_ACTION](
    { commit }: AugmentedActionContext,
    payload: ThemeModes
  ) {

    localStorage.theme = payload
    commit(AppMutationTypes.SET_THEME_MODE, payload);
  },

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  [AppActionTypes.GET_API_VERSION_ACTION](
    { commit }: AugmentedActionContext
  ) {

    let apiversion;
    if ( !('apiversion' in localStorage) ) {
      apiversion = <string>'obtaining'
    } else {
      apiversion = <string>localStorage.apiversion
    }
    return apiversion;

  },

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  [AppActionTypes.SET_API_VERSION_ACTION](
    { commit }: AugmentedActionContext,
    payload: string
  ) {

    localStorage.apiversion = payload
    commit(AppMutationTypes.SET_API_VERSION, payload);
  },

  [AppActionTypes.SET_DIRT_ACTION](
      { commit }: AugmentedActionContext,
      payload: boolean
  ) {
    commit(AppMutationTypes.SET_DIRT, payload);
  },

  async [AppActionTypes.DIRT_CONFIRM_STAY_ACTION](
      { commit, getters, dispatch }: AugmentedActionContext,
  ) {
    let theReturn : boolean = false;
    if(getters.StateDirt){
      commit(AppMutationTypes.SET_SHOW_DIRT_MODAL, true);
      await new Promise((resolve, reject)=>{
        commit(AppMutationTypes.SET_DIRT_STAY_RESOLVE, resolve);
        commit(AppMutationTypes.SET_DIRT_STAY_REJECT, reject);
      }).then(()=>{
        // stay in form
        theReturn = true
        //  dispatch(AppActionTypes.SET_DIRT_ACTION, false);
        commit(AppMutationTypes.SET_SHOW_DIRT_MODAL, false);
      }).catch(()=>{
        // leave form
        setTimeout(()=>{
          theReturn = false
          dispatch(AppActionTypes.SET_DIRT_ACTION, false);
        }, 150)
        commit(AppMutationTypes.SET_SHOW_DIRT_MODAL, false);
      })
    }
    return theReturn;
  },

  [AppActionTypes.DIRT_STAY_RESOLVE_ACTION](
      { commit, getters }: AugmentedActionContext,
  ) {
    getters.StateDirtyShowResolve()
  },
  [AppActionTypes.DIRT_STAY_REJECT_ACTION](
      { commit, getters }: AugmentedActionContext,
  ) {
    getters.StateDirtyShowReject()
  },


}
