import {UserImport, User, UserExport } from '@/types/index';

export type State = {
  users: User[],
  isLoadingUsers: boolean,
  isUsersLoaded: boolean,

  // isSavingUser: boolean,
  // userSaveError: string,

  imports: UserImport[],
  isLoadingImports: boolean,
  isImportsLoaded: boolean,

  import: UserImport | null,
  isLoadingImport: boolean,
  isImportLoaded: boolean,

  userImport: UserImport | null,
  userImportIsImporting: Boolean,
  userImportDone: Boolean,
  userImportResults: Object | null,

  userExportGenerating: Boolean,
  userExportGenerationDone: Boolean,
  userExportResults: Object | null,

  userExportsLoading: Boolean,
  userExportsLoaded: Boolean,
  userExports: UserExport[]

};

export const state: State = {
  users: [],
  isLoadingUsers: false,
  isUsersLoaded: false,

  imports: [],
  isLoadingImports: false,
  isImportsLoaded: false,

  import: null,
  isLoadingImport: false,
  isImportLoaded: false,

  userImport: null,
  userImportIsImporting: false,
  userImportDone: false,
  userImportResults: null,

  userExportGenerating: false,
  userExportGenerationDone: false,
  userExportResults: null,

  userExportsLoading: false,
  userExportsLoaded: false,
  userExports: [],
};

const _state = JSON.parse(JSON.stringify(state));
export const defaultState = (): State => {
  {
    return JSON.parse(JSON.stringify(_state))
  }
};
