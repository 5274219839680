// import { FilterOptionDef, ServiceCatalogueModel } from '@/types/index';
import { MutationTree } from "vuex";
// import { State } from './state';
// import { ServiceCatalogueMutationTypes as MutationTypes, ServiceCatalogueState as State, ChartsPieChart } from '@/types/index';
// import { ChartsPieChart } from '@/types/index';
import { ServiceCatalogueMutationTypes as MutationTypes } from "./mutation-types";
import { ServiceCatalogueState as State, defaultState } from "./state";
import {
  ServiceCatalogueItem,
  ServiceCatalogueCategory,
  ServiceCataloguePriority,
  // ServiceCatalogueChart,
  // ServiceCatalogueManufacturer,
  FieldDef,
  FilterDef,
  SortDef,
  // ServiceCatalogueField,
  // ServiceCatalogueImport,
  // ServiceCatalogueImportMapping,
  // ServiceCatalogueExport
} from "@/types/index";

export type Mutations<S = State> = {
  [MutationTypes.RESET](state: S): void;

  [MutationTypes.SET_SERVICECATALOGUEITEMS](
    state: S,
    payload: ServiceCatalogueItem[]
  ): void;
  [MutationTypes.SET_IS_LOADING_SERVICECATALOGUEITEMS](state: S, payload: boolean): void;
  [MutationTypes.SET_DELETED_SERVICECATALOGUEITEMS_DATA](state: S, payload: any): void;
  [MutationTypes.SET_IS_DELETING_SERVICECATALOGUEITEMS](state: S, payload: boolean): void;
  [MutationTypes.SET_IS_UPDATING_SERVICECATALOGUEITEMS](state: S, payload: boolean): void;
  [MutationTypes.SET_UPDATED_SERVICECATALOGUEITEMS_DATA](state: S, payload: any): void;

  [MutationTypes.SET_PAGE](state: S, payload: number): void;
  [MutationTypes.SET_PAGESIZE](state: S, payload: number): void;
  [MutationTypes.SET_TOTAL](state: S, payload: number): void;
  [MutationTypes.SET_SEARCH](state: S, payload: string | null): void;
  [MutationTypes.SET_FILTER](state: S, payload: FilterDef[]): void;
  [MutationTypes.SET_AVAILABLEFIELDS](state: S, payload: FieldDef[]): void;
  [MutationTypes.SET_SORTS](state: S, payload: SortDef[]): void;

  [MutationTypes.SET_SERVICECATALOGUEITEM](
    state: S,
    payload: ServiceCatalogueItem | null
  ): void;
  [MutationTypes.SET_SERVICECATALOGUEITEM_ERROR](state: S, payload: String | null): void;
  [MutationTypes.SET_SERVICECATALOGUEITEM_ERROR_VALIDATION](
    state: S,
    payload: any | null
  ): void;
  [MutationTypes.SET_IS_LOADING_SERVICECATALOGUEITEM](state: S, payload: boolean): void;
  [MutationTypes.SET_IS_SERVICECATALOGUEITEM_LOADED](state: S, payload: boolean): void;
  [MutationTypes.SET_IS_CREATING_SERVICECATALOGUEITEM](state: S, payload: boolean): void;
  [MutationTypes.SET_IS_SERVICECATALOGUEITEM_CREATED](state: S, payload: boolean): void;
  [MutationTypes.SET_CREATED_SERVICECATALOGUEITEM_DATA](state: S, payload: any): void;
  [MutationTypes.SET_IS_UPDATING_SERVICECATALOGUEITEM](state: S, payload: boolean): void;
  [MutationTypes.SET_IS_SERVICECATALOGUEITEM_UPDATED](state: S, payload: boolean): void;
  [MutationTypes.SET_UPDATED_SERVICECATALOGUEITEM_DATA](state: S, payload: any): void;
  [MutationTypes.SET_IS_DELETING_SERVICECATALOGUEITEM](state: S, payload: boolean): void;
  [MutationTypes.SET_DELETED_SERVICECATALOGUEITEM_SUCCEEDED](
    state: S,
    payload: boolean
  ): void;
  [MutationTypes.SET_DELETED_SERVICECATALOGUEITEM_DATA](state: S, payload: any): void;

  [MutationTypes.SET_SERVICECATALOGUEITEM_TO_PREVIEW_MOD](
    state: S,
    payload: string | null
  ): void;
  [MutationTypes.SET_SERVICECATALOGUEITEM_PREVIEW_OPEN_STATE](
    state: S,
    payload: boolean
  ): void;

  [MutationTypes.SET_SERVICECATALOGUE_CATEGORIES](
    state: S,
    payload: ServiceCatalogueCategory[]
  ): void;
  [MutationTypes.SET_IS_LOADING_SERVICECATALOGUE_CATEGORIES](
    state: S,
    payload: boolean
  ): void;
  [MutationTypes.SET_IS_SERVICECATALOGUE_CATEGORIES_LOADED](
    state: S,
    payload: boolean
  ): void;
  [MutationTypes.SET_IS_SAVING_SERVICECATALOGUE_CATEGORIES](
    state: S,
    payload: boolean
  ): void;

  [MutationTypes.SET_IS_LOADING_SERVICECATALOGUE_CATEGORY](
    state: S,
    payload: boolean
  ): void;
  [MutationTypes.SET_IS_SERVICECATALOGUE_CATEGORY_LOADED](
    state: S,
    payload: boolean
  ): void;
  [MutationTypes.SET_IS_SAVING_SERVICECATALOGUE_CATEGORY](
    state: S,
    payload: boolean
  ): void;

  [MutationTypes.SET_IS_SERVICECATALOGUE_CATEGORY_SAVED](
    state: S,
    payload: boolean
  ): void;
  [MutationTypes.SET_SERVICECATALOGUE_CATEGORY_SAVE_ERROR](
    state: S,
    payload: string | null
  ): void;

  [MutationTypes.SET_IS_DELETING_SERVICECATALOGUE_CATEGORY](
    state: S,
    payload: boolean
  ): void;

  [MutationTypes.SET_SERVICECATALOGUE_PRIORITIES](
      state: S,
      payload: ServiceCataloguePriority[]
  ): void;
  [MutationTypes.SET_IS_LOADING_SERVICECATALOGUE_PRIORITIES](
      state: S,
      payload: boolean
  ): void;
  [MutationTypes.SET_IS_SERVICECATALOGUE_PRIORITIES_LOADED](
      state: S,
      payload: boolean
  ): void;
  [MutationTypes.SET_IS_SAVING_SERVICECATALOGUE_PRIORITIES](
      state: S,
      payload: boolean
  ): void;

  [MutationTypes.SET_IS_LOADING_SERVICECATALOGUE_PRIORITY](
      state: S,
      payload: boolean
  ): void;
  [MutationTypes.SET_IS_SERVICECATALOGUE_PRIORITY_LOADED](
      state: S,
      payload: boolean
  ): void;
  [MutationTypes.SET_IS_SAVING_SERVICECATALOGUE_PRIORITY](
      state: S,
      payload: boolean
  ): void;

  [MutationTypes.SET_IS_SERVICECATALOGUE_PRIORITY_SAVED](
      state: S,
      payload: boolean
  ): void;
  [MutationTypes.SET_SERVICECATALOGUE_PRIORITY_SAVE_ERROR](
      state: S,
      payload: string | null
  ): void;

  [MutationTypes.SET_IS_DELETING_SERVICECATALOGUE_PRIORITY](
      state: S,
      payload: boolean
  ): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.RESET](state) {
    Object.assign(state, defaultState());
    // this.replaceState(defaultState())
  },

  [MutationTypes.SET_SERVICECATALOGUEITEMS](
    state: State,
    serviceCatalogueItems: ServiceCatalogueItem[]
  ) {
    state.serviceCatalogueItems = serviceCatalogueItems;
  },
  [MutationTypes.SET_IS_LOADING_SERVICECATALOGUEITEMS](
    state: State,
    isLoadingServiceCatalogueItems: boolean
  ) {
    state.isLoadingServiceCatalogueItems = isLoadingServiceCatalogueItems;
  },
  [MutationTypes.SET_DELETED_SERVICECATALOGUEITEMS_DATA](
    state: State,
    deletedServiceCatalogueItemsData: any
  ) {
    state.deletedServiceCatalogueItemsData = deletedServiceCatalogueItemsData;
  },
  [MutationTypes.SET_IS_DELETING_SERVICECATALOGUEITEMS](
    state: State,
    isDeletingServiceCatalogueItems: boolean
  ) {
    state.isDeletingServiceCatalogueItems = isDeletingServiceCatalogueItems;
  },
  [MutationTypes.SET_IS_UPDATING_SERVICECATALOGUEITEMS](
    state: State,
    isUpdatingServiceCatalogueItems: boolean
  ) {
    // debugger; // eslint-disable-line
    state.isUpdatingServiceCatalogueItems = isUpdatingServiceCatalogueItems;
  },
  [MutationTypes.SET_UPDATED_SERVICECATALOGUEITEMS_DATA](
    state: State,
    updatedServiceCatalogueItemData: any
  ) {
    state.updatedServiceCatalogueItemData = updatedServiceCatalogueItemData;
  },

  [MutationTypes.SET_DELETED_SERVICECATALOGUEITEM_SUCCEEDED](
    state: State,
    deletedServiceCatalogueItemSucceded: boolean
  ) {
    // debugger; // eslint-disable-line
    state.deletedServiceCatalogueItemSucceded = deletedServiceCatalogueItemSucceded;
  },

  [MutationTypes.SET_PAGE](state: State, page: number) {
    state.page = page;
  },

  [MutationTypes.SET_PAGESIZE](state: State, pageSize: number) {
    state.pageSize = pageSize;
  },

  [MutationTypes.SET_TOTAL](state: State, total: number) {
    state.total = total;
  },

  [MutationTypes.SET_SEARCH](state: State, search: string | null) {
    state.search = search;
  },

  [MutationTypes.SET_FILTER](state: State, filters: FilterDef[]) {
    state.filters = filters;
  },

  [MutationTypes.SET_AVAILABLEFIELDS](state: State, availableFields: FieldDef[]) {
    state.availableFields = availableFields;
  },

  [MutationTypes.SET_SORTS](state: State, sorts: SortDef[]) {
    state.sorts = sorts;
  },

  [MutationTypes.SET_SERVICECATALOGUEITEM](
    state: State,
    serviceCatalogueItem: ServiceCatalogueItem | null
  ) {
    state.serviceCatalogueItem =
      serviceCatalogueItem === null
        ? null
        : ServiceCatalogueItem.fromObject(serviceCatalogueItem);
  },
  [MutationTypes.SET_SERVICECATALOGUEITEM_ERROR](state: State, message: string | null) {
    state.serviceCatalogueItemError = message;
  },
  [MutationTypes.SET_SERVICECATALOGUEITEM_ERROR_VALIDATION](
    state: State,
    serviceCatalogueItemErrorValidation: String | null
  ) {
    state.serviceCatalogueItemErrorValidation = serviceCatalogueItemErrorValidation;
  },
  [MutationTypes.SET_IS_LOADING_SERVICECATALOGUEITEM](
    state: State,
    isLoadingServiceCatalogueItem: boolean
  ) {
    state.isLoadingServiceCatalogueItem = isLoadingServiceCatalogueItem;
  },
  [MutationTypes.SET_IS_SERVICECATALOGUEITEM_LOADED](
    state: State,
    isServiceCatalogueItemLoaded: boolean
  ) {
    state.isServiceCatalogueItemLoaded = isServiceCatalogueItemLoaded;
  },
  [MutationTypes.SET_IS_CREATING_SERVICECATALOGUEITEM](
    state: State,
    isCreatingServiceCatalogueItem: boolean
  ) {
    state.isCreatingServiceCatalogueItem = isCreatingServiceCatalogueItem;
  },
  [MutationTypes.SET_IS_SERVICECATALOGUEITEM_CREATED](
    state: State,
    isServiceCatalogueItemCreated: boolean
  ) {
    state.isServiceCatalogueItemCreated = isServiceCatalogueItemCreated;
  },
  [MutationTypes.SET_CREATED_SERVICECATALOGUEITEM_DATA](
    state: State,
    createdServiceCatalogueItemData: any
  ) {
    state.createdServiceCatalogueItemData = createdServiceCatalogueItemData;
  },
  [MutationTypes.SET_IS_UPDATING_SERVICECATALOGUEITEM](
    state: State,
    isUpdatingServiceCatalogueItem: boolean
  ) {
    state.isUpdatingServiceCatalogueItem = isUpdatingServiceCatalogueItem;
  },
  [MutationTypes.SET_IS_SERVICECATALOGUEITEM_UPDATED](
    state: State,
    isServiceCatalogueItemUpdated: boolean
  ) {
    state.isServiceCatalogueItemUpdated = isServiceCatalogueItemUpdated;
  },
  [MutationTypes.SET_UPDATED_SERVICECATALOGUEITEM_DATA](
    state: State,
    updatedServiceCatalogueItemData: any
  ) {
    state.updatedServiceCatalogueItemData = updatedServiceCatalogueItemData;
  },
  [MutationTypes.SET_IS_DELETING_SERVICECATALOGUEITEM](
    state: State,
    isDeletingServiceCatalogueItem: boolean
  ) {
    state.isDeletingServiceCatalogueItem = isDeletingServiceCatalogueItem;
  },
  [MutationTypes.SET_DELETED_SERVICECATALOGUEITEM_DATA](
    state: State,
    deletedServiceCatalogueItemData: any
  ) {
    state.deletedServiceCatalogueItemData = deletedServiceCatalogueItemData;
  },

  [MutationTypes.SET_SERVICECATALOGUEITEM_TO_PREVIEW_MOD](
    state: State,
    serviceCatalogueItemToPreviewMod: string | null
  ) {
    state.serviceCatalogueItemToPreviewMod = serviceCatalogueItemToPreviewMod;
  },
  [MutationTypes.SET_SERVICECATALOGUEITEM_PREVIEW_OPEN_STATE](
    state: State,
    serviceCatalogueItemPreviewOpenState: boolean
  ) {
    state.serviceCatalogueItemPreviewOpenState = serviceCatalogueItemPreviewOpenState;
  },

  [MutationTypes.SET_SERVICECATALOGUE_CATEGORIES](
    state: State,
    serviceCatalogueCategories: ServiceCatalogueCategory[]
  ) {
    state.serviceCatalogueCategories = serviceCatalogueCategories; // categories.map(category => ServiceCatalogueCategory.fromObject(categories));
    // const categoryFilter = state.filters.find(f => f.id === 'category');
    // if (categoryFilter) {
    //   categoryFilter.options = state.serviceCatalogueCategories;
    // }
  },
  [MutationTypes.SET_IS_LOADING_SERVICECATALOGUE_CATEGORIES](
    state: State,
    isLoadingServiceCatalogueCategories: boolean
  ) {
    state.isLoadingServiceCatalogueCategories = isLoadingServiceCatalogueCategories;
  },
  [MutationTypes.SET_IS_SERVICECATALOGUE_CATEGORIES_LOADED](
    state: State,
    isServiceCatalogueCategoriesLoaded: boolean
  ) {
    state.isServiceCatalogueCategoriesLoaded = isServiceCatalogueCategoriesLoaded;
  },
  [MutationTypes.SET_IS_SAVING_SERVICECATALOGUE_CATEGORIES](
    state: State,
    isSavingServiceCatalogueCategories: boolean
  ) {
    state.isSavingServiceCatalogueCategories = isSavingServiceCatalogueCategories;
  },

  [MutationTypes.SET_IS_LOADING_SERVICECATALOGUE_CATEGORY](
    state: State,
    isLoadingServiceCatalogueCategory: boolean
  ) {
    state.isLoadingServiceCatalogueCategory = isLoadingServiceCatalogueCategory;
  },
  [MutationTypes.SET_IS_SERVICECATALOGUE_CATEGORY_LOADED](
    state: State,
    isServiceCatalogueCategoryLoaded: boolean
  ) {
    state.isServiceCatalogueCategoryLoaded = isServiceCatalogueCategoryLoaded;
  },
  [MutationTypes.SET_IS_SAVING_SERVICECATALOGUE_CATEGORY](
    state: State,
    isSavingServiceCatalogueCategory: boolean
  ) {
    state.isSavingServiceCatalogueCategory = isSavingServiceCatalogueCategory;
  },

  [MutationTypes.SET_IS_SERVICECATALOGUE_CATEGORY_SAVED](
    state: State,
    isServiceCatalogueCategorySaved: boolean
  ) {
    state.isServiceCatalogueCategorySaved = isServiceCatalogueCategorySaved;
  },

  [MutationTypes.SET_SERVICECATALOGUE_CATEGORY_SAVE_ERROR](
    state: State,
    ServiceCatalogueCategorySaveError: string | null
  ) {
    state.serviceCatalogueCategorySaveError = ServiceCatalogueCategorySaveError;
  },

  [MutationTypes.SET_IS_DELETING_SERVICECATALOGUE_CATEGORY](
    state: State,
    isDeletingServiceCatalogueCategory: boolean
  ) {
    state.isDeletingServiceCatalogueCategory = isDeletingServiceCatalogueCategory;
  },

  [MutationTypes.SET_SERVICECATALOGUE_PRIORITIES](
      state: State,
      serviceCataloguePriorities: ServiceCataloguePriority[]
  ) {
    state.serviceCataloguePriorities = serviceCataloguePriorities; // priorities.map(priority => ServiceCataloguePriority.fromObject(priorities));
    // const priorityFilter = state.filters.find(f => f.id === 'priority');
    // if (priorityFilter) {
    //   priorityFilter.options = state.serviceCataloguePriorities;
    // }
  },
  [MutationTypes.SET_IS_LOADING_SERVICECATALOGUE_PRIORITIES](
      state: State,
      isLoadingServiceCataloguePriorities: boolean
  ) {
    state.isLoadingServiceCataloguePriorities = isLoadingServiceCataloguePriorities;
  },
  [MutationTypes.SET_IS_SERVICECATALOGUE_PRIORITIES_LOADED](
      state: State,
      isServiceCataloguePrioritiesLoaded: boolean
  ) {
    state.isServiceCataloguePrioritiesLoaded = isServiceCataloguePrioritiesLoaded;
  },
  [MutationTypes.SET_IS_SAVING_SERVICECATALOGUE_PRIORITIES](
      state: State,
      isSavingServiceCataloguePriorities: boolean
  ) {
    state.isSavingServiceCataloguePriorities = isSavingServiceCataloguePriorities;
  },

  [MutationTypes.SET_IS_LOADING_SERVICECATALOGUE_PRIORITY](
      state: State,
      isLoadingServiceCataloguePriority: boolean
  ) {
    state.isLoadingServiceCataloguePriority = isLoadingServiceCataloguePriority;
  },
  [MutationTypes.SET_IS_SERVICECATALOGUE_PRIORITY_LOADED](
      state: State,
      isServiceCataloguePriorityLoaded: boolean
  ) {
    state.isServiceCataloguePriorityLoaded = isServiceCataloguePriorityLoaded;
  },
  [MutationTypes.SET_IS_SAVING_SERVICECATALOGUE_PRIORITY](
      state: State,
      isSavingServiceCataloguePriority: boolean
  ) {
    state.isSavingServiceCataloguePriority = isSavingServiceCataloguePriority;
  },

  [MutationTypes.SET_IS_SERVICECATALOGUE_PRIORITY_SAVED](
      state: State,
      isServiceCataloguePrioritySaved: boolean
  ) {
    state.isServiceCataloguePrioritySaved = isServiceCataloguePrioritySaved;
  },

  [MutationTypes.SET_SERVICECATALOGUE_PRIORITY_SAVE_ERROR](
      state: State,
      ServiceCataloguePrioritySaveError: string | null
  ) {
    state.serviceCataloguePrioritySaveError = ServiceCataloguePrioritySaveError;
  },

  [MutationTypes.SET_IS_DELETING_SERVICECATALOGUE_PRIORITY](
      state: State,
      isDeletingServiceCataloguePriority: boolean
  ) {
    state.isDeletingServiceCataloguePriority = isDeletingServiceCataloguePriority;
  },
};
