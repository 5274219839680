// TODO: Remove no-shadow eslint disable after fixed: https://github.com/typescript-eslint/typescript-eslint/issues/2484
// eslint-disable-next-line no-shadow
export enum UserMutationTypes {
  RESET = "reset",

  SET_USERS = 'setUsers',
  SET_IS_LOADING_USERS = 'setIsLoadingUsers',
  SET_IS_USERS_LOADED = 'setIsUsersLoaded',

  SET_USER_IMPORT = "SetUserImport",
  SET_USER_IMPORT_MAPPING = "SetUserImportMapping",

  SET_USER_IMPORT_IS_IMPORTING = "SetUserImportIsImporting",
  SET_USER_IMPORT_DONE = "SetUserImportDone",
  SET_USER_IMPORT_RESULTS = "SetUserImportResults",

  SET_USER_EXPORT_IS_GENERATING = "SetUserExportIsGenerating",
  SET_USER_EXPORT_GENERATE_DONE = "SetUserExportGenerateDone",
  SET_USER_EXPORT_GENERATE_RESULTS = "SetUserExportGenerateResults",

  SET_USER_EXPORTS_IS_LOADING = "SetUserExportsIsLoading",
  SET_USER_EXPORTS_LEADED = "SetUserExportsLoaded",
  SET_USER_EXPORTS = "SetUserExports"
}
