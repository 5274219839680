/* eslint-disable no-unused-vars */
import { FieldDef, OFieldType, OActionType, FieldAction } from "./FieldDef";
import {FilterDef, OFilterType, OOptionsType} from "./FilterDef";
import {reactive} from "vue";

const OCostCurrency = {
  USD: "USD",
  EUR: "EUR",
  GBP: "GBP",
} as const;
type CostCurrency = keyof typeof OCostCurrency;

const OEstimationType = {
  hours: "Hours",
  days: "Days",
} as const;
type EstimationType = keyof typeof OEstimationType;

class ServiceCatalogueItem {
  _id: number | string | readonly string[];
  name: string;
  categoryName: string;
  categoryIcon: string | null;
  categoryIconUrl: string | null;
  category: number | string | readonly string[];
  priorityName: string;
  priority: number | string | readonly string[];
  description: string;
  cost: number | null;
  costCurrency: CostCurrency | null;
  timeEstimation: string | null;
  timeEstimationType: EstimationType | null;

  constructor(
    _id: number | string | readonly string[],
    name: string,
    category: number | string | readonly string[],
    categoryName: string,
    categoryIcon: string | null,
    categoryIconUrl: string | null,
    priority: number | string | readonly string[],
    priorityName: string,
    description: string,
    cost: number | null,
    costCurrency: CostCurrency | null,
    timeEstimation: string | null,
    timeEstimationType: EstimationType | null
  ) {
    this._id = _id;
    this.name = name;
    this.category = category;
    this.categoryName = categoryName;
    this.categoryIcon = categoryIcon;
    this.categoryIconUrl = categoryIconUrl;
    this.priority = priority;
    this.priorityName = priorityName;
    this.description = description;
    this.cost = cost;
    this.costCurrency = costCurrency;
    this.timeEstimation = timeEstimation;
    this.timeEstimationType = timeEstimationType;
  }

  static fromObject(obj: any): ServiceCatalogueItem {
    return new ServiceCatalogueItem(
      obj._id,
      obj.name,
      obj.category,
      obj.categoryName,
      obj.categoryIcon,
      obj.categoryIconUrl,
      obj.priority,
      obj.priorityName,
      obj.description,
      obj.cost,
      obj.costCurrency,
      obj.timeEstimation,
      obj.timeEstimationType,
    );
  }

  static getDefaultObject(): ServiceCatalogueItem {
    return ServiceCatalogueItem.fromObject({
      name: "",
      category: "",
      categoryName: "",
      categoryIcon: null,
      categoryIconUrl: null,
      priority: "",
      priorityName: "",
      description: "",
      cost: null,
      costCurrency: null,
      timeEstimation: null,
      timeEstimationType: null,
    });
  }
}

const ServiceCatalogueItemListFields = [
  FieldDef.fromObject({
    id: "_id",
    title: "ID",
    available: true,
    show: false,
    type: OFieldType.id,
    searchable: false,
    actions: [
      // FieldAction.fromObject({
      //   id: 'view',
      //   text: 'View',
      //   icon: null,
      //   type: OActionType.view,
      //   color: "themeprimary"
      // })
    ],
    // [OActionType.view],
  }),
  FieldDef.fromObject({
    id: "name",
    title: "Name",
    available: true,
    show: true,
    type: OFieldType.String,
    sortable: true,
    searchable: true,
    actions: [
      FieldAction.fromObject({
        id: "details",
        text: "Details",
        icon: null,
        type: OActionType.view,
        color: "themeprimary",
      }),
    ],
    //[OActionType.edit],
  }),
  FieldDef.fromObject({
    id: "categoryName",
    title: "Category",
    available: true,
    show: true,
    type: OFieldType.String,
    sortable: true,
    searchable: true,
    actions: [],
  }),
  FieldDef.fromObject({
    id: "priorityName",
    title: "Priority",
    available: true,
    show: true,
    type: OFieldType.String,
    sortable: true,
    searchable: true,
    actions: [],
  }),
  FieldDef.fromObject({
    id: "cost",
    title: "Cost",
    available: true,
    show: true,
    type: OFieldType.Money,
    sortable: true,
    searchable: true,
    actions: [],
  }),
  FieldDef.fromObject({
    id: "timeEstimation",
    title: "Estimated Delivery Time",
    available: true,
    show: true,
    type: OFieldType.Estimation,
    sortable: false,
    searchable: true,
    actions: [],
  }),
  FieldDef.fromObject({
    id: "actions",
    title: "Actions",
    available: true,
    show: true,
    type: OFieldType.Actions,
    //actions: [OActionType.view, OActionType.edit],
    searchable: false,
    actions: [
      FieldAction.fromObject({
        id: "details",
        text: "Details",
        icon: null,
        type: OActionType.view,
        color: "themeprimary",
      }),
      // FieldAction.fromObject({
      //   id: 'edit',
      //   text: 'Edit',
      //   icon: null,
      //   type: OActionType.edit,
      //   color: "themeprimary"
      // }),
      FieldAction.fromObject({
        id: "delete",
        text: "Delete",
        icon: null,
        type: OActionType.delete,
        color: "red",
      }),
    ],
    //  [OActionType.edit, OActionType.delete],
  }),
];

class ServiceCatalogueCategory {
  _id: number | string | readonly string[];
  name: string;
  order: number;
  icon: string;
  iconUrl: string;
  key: string;

  constructor(
    _id: number | string | readonly string[],
    name: string,
    order: number,
    icon: string,
    iconUrl: string,
    key: string
  ) {
    this._id = _id;
    this.name = name;
    this.order = order;
    this.icon = icon;
    this.iconUrl = iconUrl;
    this.key = key;
  }

  static fromObject(obj: any): ServiceCatalogueCategory {
    if (obj.subCategories) {
      return new ServiceCatalogueCategory(
        obj._id,
        obj.name,
        obj.order,
        obj.icon,
        obj.iconUrl,
        obj.key
      );
    }
    return new ServiceCatalogueCategory(
      obj._id,
      obj.name,
      obj.order,
      obj.icon,
      obj.iconUrl,
      obj.key
    );
  }
}

class ServiceCataloguePriority {
  _id: number | string | readonly string[];
  name: string;
  order: number;
  key: string;

  constructor(
      _id: number | string | readonly string[],
      name: string,
      order: number,
      key: string
  ) {
    this._id = _id;
    this.name = name;
    this.order = order;
    this.key = key;
  }

  static fromObject(obj: any): ServiceCataloguePriority{
    return new ServiceCataloguePriority(
        obj._id,
        obj.name,
        obj.order,
        obj.key
    );
  }
}

// debugger; // eslint-disable-line no-debugger
const ServiceCatalogueItemListFilters = [
  // FilterDef.fromObject({
  //   id: "name",
  //   queryParam: "name",
  //   title: "Name",
  //   type: OFilterType.StringSearch,
  //   optionsType: null,
  //   options: [],
  //   value: "",
  // }),
  // FilterDef.fromObject({
  //   id: "category",
  //   queryParam: "category",
  //   title: "Category",
  //   type: OFilterType.Enum,
  //   optionsType: OOptionsType.ServiceCatalogueCategory,
  //   options: reactive([]),
  //   value: "",
  // }),
  FilterDef.fromObject({
    id: "priority",
    queryParam: "priority",
    title: "Priority",
    type: OFilterType.Enum,
    optionsType: OOptionsType.ServiceCataloguePriority,
    options: reactive([]),
    value: "",
  }),
];
// debugger; // eslint-disable-line no-debugger

export {
  ServiceCatalogueItem,
  ServiceCatalogueCategory,
  ServiceCataloguePriority,
  OCostCurrency,
  CostCurrency,
  OEstimationType,
  EstimationType,
  ServiceCatalogueItemListFields,
  ServiceCatalogueItemListFilters,
};
