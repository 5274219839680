import { MutationTree } from 'vuex';
import { State, defaultState } from './state';
import { UserMutationTypes as MutationTypes } from './mutation-types';
import {UserExport, User, UserImport, UserImportMapping} from '@/types/index';

export type Mutations<S = State> = {
  [MutationTypes.RESET](state: S): void;

  [MutationTypes.SET_USERS](state: S, payload: User[]): void;
  [MutationTypes.SET_IS_LOADING_USERS](state: S, payload: boolean): void;
  [MutationTypes.SET_IS_USERS_LOADED](state: S, payload: boolean): void;

  [MutationTypes.SET_USER_IMPORT](state: S, payload: UserImport | null): void;
  [MutationTypes.SET_USER_IMPORT_MAPPING](state: S, payload: UserImportMapping): void;

  [MutationTypes.SET_USER_IMPORT_IS_IMPORTING](state: S, payload: Boolean): void;
  [MutationTypes.SET_USER_IMPORT_DONE](state: S, payload: Boolean): void;
  [MutationTypes.SET_USER_IMPORT_RESULTS](state: S, payload: Object | null): void;

  [MutationTypes.SET_USER_EXPORT_IS_GENERATING](state: S, payload: Boolean): void;
  [MutationTypes.SET_USER_EXPORT_GENERATE_DONE](state: S, payload: Boolean): void;
  [MutationTypes.SET_USER_EXPORT_GENERATE_RESULTS](state: S, payload: Object | null): void;

  [MutationTypes.SET_USER_EXPORTS_IS_LOADING](state: S, payload: Boolean): void;
  [MutationTypes.SET_USER_EXPORTS_LEADED](state: S, payload: Boolean): void;
  [MutationTypes.SET_USER_EXPORTS](state: S, payload: UserExport[]): void;
}

export const mutations: MutationTree<State> & Mutations = {

  [MutationTypes.RESET](state) {
    Object.assign(state, defaultState())
    // this.replaceState(defaultState())
  },

  [MutationTypes.SET_USERS](state, users) {
    state.users = users
  },

  [MutationTypes.SET_IS_LOADING_USERS](state, isLoadingUsers) {
    state.isLoadingUsers = isLoadingUsers
  },

  [MutationTypes.SET_IS_USERS_LOADED](state, isUsersLoaded) {
    state.isUsersLoaded = isUsersLoaded
  },

  [MutationTypes.SET_USER_IMPORT](state: State, userImport: UserImport | null) {
    state.userImport = userImport;
  },

  [MutationTypes.SET_USER_IMPORT_MAPPING](state: State, userImportMapping: UserImportMapping) {
    if (state.userImport)
      state.userImport.mapping = userImportMapping;
  },

  [MutationTypes.SET_USER_IMPORT_IS_IMPORTING](state: State, userImportIsImporting: Boolean) {
    state.userImportIsImporting = userImportIsImporting;
  },

  [MutationTypes.SET_USER_IMPORT_DONE](state: State, userImportDone: Boolean) {
    state.userImportDone = userImportDone;
  },

  [MutationTypes.SET_USER_IMPORT_RESULTS](state: State, userImportResults: Object | null) {
    state.userImportResults = userImportResults;
  },

  [MutationTypes.SET_USER_EXPORT_IS_GENERATING](state: State, userExportGenerating: Boolean) {
    state.userExportGenerating = userExportGenerating;
  },

  [MutationTypes.SET_USER_EXPORT_GENERATE_DONE](state: State, userExportGenerationDone: Boolean) {
    state.userExportGenerationDone = userExportGenerationDone;
  },

  [MutationTypes.SET_USER_EXPORT_GENERATE_RESULTS](state: State, userExportResults: Object | null) {
    state.userExportResults = userExportResults;
  },

  [MutationTypes.SET_USER_EXPORTS_IS_LOADING](state: State, userExportsLoading: Boolean) {
    state.userExportsLoading = userExportsLoading;
  },

  [MutationTypes.SET_USER_EXPORTS_LEADED](state: State, userExportsLoaded: Boolean) {
    state.userExportsLoaded = userExportsLoaded;
  },

  [MutationTypes.SET_USER_EXPORTS](state: State, userExports: UserExport[]) {
    state.userExports = userExports;
  }

};
