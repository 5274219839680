
import { defineComponent } from "vue";
// import ChatBot from "@/components/ChatBot.vue";
import TopMenu from "@/layout/LayoutComponents/TopMenu.vue";
import NarrowSidebar from "@/layout/LayoutComponents/NarrowSidebar.vue";
import Footer from "@/layout/LayoutComponents/Footer.vue";
import { userRoles } from "@/composables/UserRole";
// import Alert from "@/components/Dumb/Alert.vue";
// import { Severity as AlertSeverity } from "@/components/Dumb/Alert.vue";
import { OErrorSeverityType as AlertSeverity } from "@/types/Error";
import { mapGetters } from "vuex";

// const accessToken = localStorage.getItem("accessToken");
// const refreshToken = localStorage.getItem("refreshToken");

export default defineComponent({
  name: "ZebritApp",
  data: function () {
    return {
      AlertSeverity: AlertSeverity,
    };
  },
  components: {
    // ChatBot,
    TopMenu,
    NarrowSidebar,
    // Alert,
    Footer,
  },
  computed: {
    ...mapGetters({
      token: "auth/StateAccessToken",
    }),
    theme() {
      if ( this.isLogedIn ) return "default";
      return this.$route.meta.theme || "default";
    },
    hasToken() {
      return this.token != null; // ?  accessToken != null || refreshToken != null;
    },
    isLogedIn() {
      if (!this.hasToken) return false;
      const { user } = userRoles();
      if (!user) return false;
      return true;
    },
    user() {
      if (!this.hasToken) return null;
      const { user } = userRoles();
      return user;
    },
    organisation() {
      if (!this.hasOrganisation) return null;
      return this.user?.organisation;
    },
    hasOrganisation() {
      // debugger; // eslint-disable-line
      // console.log('hasOrganisation');
      if (!this.hasToken) return false;
      const _user = this.user;
      // console.log(user);
      if (!_user || !_user.organisation) return false;
      return true;
    },
    showTopMenu() {
      return this.hasOrganisation;
    },
    showNarrowSidebar() {
      return this.hasOrganisation;
    },
    showChatBot() {
      return this.hasOrganisation;
    },
  },
  // methods: {
  //   isLogedIn() {
  //     const { user } = userRoles();
  //     if (!user) return false;
  //     return true;
  //   },
  //   hasOrganisation() {
  //     debugger; // eslint-disable-line
  //     const { user } = userRoles();
  //     if (!user || !user.organisation) return false;
  //     return true;
  //   },
  //   showTopMenu() {
  //     return this.hasOrganisation();
  //   },
  //   showNarrowSidebar() {
  //     return this.hasOrganisation();
  //   },
  //   showChatBot() {
  //     return this.hasOrganisation();
  //   },
  // },
  watch: {
    $route(to) {
      if (to.meta && to.meta.title) document.title = "zebrit :: " + to.meta.title;
      else if (to.name) document.title = "zebrit :: " + to.name;
    },
  },
});
