const OFieldType = {
    id: "id",
    _id: "_id",
    String: "String",
    Number: "Number",
    DateTime: "DateTime",
    Date: "Date",
    Time: "Time",
    Duration: "Duration",
    User: "User",
    Money: "Money",
    Estimation: "Estimation",
    Image: "Image",
    Avatar: "Avatar",
    AssetPicture: "AssetPicture",
    TickerType: "TickerType",
    Type: "Type",
    Severity: "Severity",
    Status: "Status",
    Actions: "Actions",
    Boolean: "Boolean",
    Assignee: "Assignee",
    RadioCheck: "RadioCheck",
    ServiceType: "ServiceType",
} as const;
type FieldType = typeof OFieldType[keyof typeof OFieldType];

const OActionType = {
    none: null,
    view: "view",
    edit: "edit",
    delete: "delete",
    check: "check",
} as const;
type ActionType = typeof OActionType[keyof typeof OActionType];

class FieldAction {
    id: string;
    text: string;
    type: ActionType;
    icon: any;
    color: string;
    constructor(
        id: string,
        text: string,
        icon: any,
        type: ActionType,
        color: string
    ) {
        this.id = id
        this.text = text
        this.type = type
        this.icon = icon
        this.color = color
    }

    static fromObject(obj: any): FieldAction {
        return new FieldAction(
            obj.id,
            obj.text,
            obj.icon,
            obj.type,
            obj.color
        )
    }

}
// const OActionTypeColor = {
//     none: null,
//     view: "themeprimary",
//     edit: "themeprimary",
//     delete: "red"
// } as const;
// // type ActionTypeColor = typeof OActionTypeColor[keyof typeof OActionTypeColor];

// // const ActionTypeColor: { [key: string]: string; } = {};

// const ActionTypeColor: { [key: string]: string; } = {};
// ActionTypeColor[OActionType.view] = OActionTypeColor.view;
// ActionTypeColor[OActionType.edit] = OActionTypeColor.edit;
// ActionTypeColor[OActionType.delete] = OActionTypeColor.delete;


class FieldDef {
    id: string;
    title: string;
    available: boolean;
    show: boolean;
    type: FieldType;
    filterable: boolean;
    sortable: boolean;
    searchable: boolean;
    actions: FieldAction[];
    constructor(
        id: string,
        title: string,
        available: boolean,
        show: boolean,
        type: FieldType,
        filterable: boolean,
        sortable: boolean,
        searchable: boolean,
        actions: FieldAction[]
    ) {
        this.id = id
        this.title = title
        this.available = available
        this.show = show
        this.type = type
        this.filterable = filterable
        this.sortable = sortable
        this.searchable = searchable
        this.actions = actions
    }

    static fromObject(obj: any): FieldDef {
        return new FieldDef(
            obj.id,
            obj.title,
            obj.available,
            obj.show,
            <FieldType>obj.type,
            obj.filterable,
            obj.sortable,
            obj.searchable,
            <FieldAction[]>obj.actions
        )
    }

    // static getDefaultObject(): FieldDef {
    //     return FieldDef.fromObject({
    //         id: 'FieldDefId',
    //         title: 'FieldDef Title',
    //         available: true,
    //         show: true,
    //         type: <FieldType>'id',
    //         filterable: true,
    //         sortable: true,
    //         actions: <ActionType[]>[OActionType.view]
    //     })
    // }
}

export {
    FieldDef,
    FieldType,
    OFieldType,
    ActionType,
    OActionType,
    FieldAction
    // OActionTypeColor,
    // ActionTypeColor
}
