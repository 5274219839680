import {FilterDef, Staffing, SortDef, FieldDef, FilterOptionDef, StaffingChecklist} from "@/types/index";

export type StaffingState = {
  staffings: Staffing[];
  isLoadingStaffings: boolean;
  isDeletingStaffings: boolean;
  deletedStaffingsData: null;
  isUpdatingStaffings: boolean;
  updatedStaffingsData: null;

  page: number;
  pageSize: number;
  checklistPage: number;
  checklistPageSize: number;
  total: number;
  search: string | null;
  searchChecklist: string | null;
  filters: FilterDef[];
  availableFields: FieldDef[];
  sorts: SortDef[];
  sortsChecklist: SortDef[];

  staffingsKanban: Map<string, Staffing[]>;
  isLoadingStaffingsKanban: Map<string, boolean>;
  isDeletingStaffingsKanban: Map<string, boolean>;
  isUpdatingStaffingsKanban: Map<string, boolean>;

  staffingChecklist: StaffingChecklist[];
  isLoadingStaffingChecklist: boolean;
  isDeletingStaffingChecklist: boolean;
  isUpdatingStaffingChecklist: boolean;

  pageKanban: Map<string, number>;
  pageSizeKanban: Map<string, number>;
  totalKanban: Map<string, number>;
  searchKanban: Map<string, string | null>;
  filtersKanban: Map<string, FilterDef[]>;
  availableFieldsKanban: Map<string, FieldDef[]>;
  sortsKanban: Map<string, SortDef[]>;

  staffing: Staffing | null;
  staffingError: String | null;
  staffingChecklistError: String | null;
  staffingErrorValidation: any;
  isLoadingStaffing: boolean;
  isStaffingLoaded: boolean;
  isCreatingStaffing: boolean;
  createdStaffingData: null;
  isUpdatingStaffing: boolean;
  isStaffingUpdated: boolean;
  isStaffingCreated: boolean;
  updatedStaffingData: null;
  isDeletingStaffing: boolean;
  deletedStaffingSucceeded: boolean;
  deletedStaffingData: null;

  staffingToPreviewMod: string | null;
  staffingPreviewOpenState: boolean;

  statuses: FilterOptionDef[];
  isLoadingStatuses: boolean;
  isStatusesLoaded: boolean;
};

export const state: StaffingState = {
  staffings: [],

  page: 1,
  pageSize: 10,
  checklistPage: 1,
  checklistPageSize: 100,
  total: 0,
  search: null,
  searchChecklist: null,
  // filters: filters,
  filters: [],
  availableFields: [],
  sorts: [],
  sortsChecklist: [],

  isLoadingStaffings: false,
  isDeletingStaffings: false,
  deletedStaffingsData: null,
  isUpdatingStaffings: false,
  updatedStaffingsData: null,

  staffingsKanban: new Map<string, Staffing[]>([]),
  isLoadingStaffingsKanban: new Map<string, boolean>(),
  isDeletingStaffingsKanban: new Map<string, boolean>(),
  isUpdatingStaffingsKanban: new Map<string, boolean>(),

  staffingChecklist: [],
  isLoadingStaffingChecklist: false,
  isDeletingStaffingChecklist: false,
  isUpdatingStaffingChecklist: false,

  pageKanban: new Map<string, number>(),
  pageSizeKanban: new Map<string, number>(),
  totalKanban: new Map<string, number>(),
  searchKanban: new Map<string, string | null>(),
  filtersKanban: new Map<string, FilterDef[]>(),
  availableFieldsKanban: new Map<string, FieldDef[]>(),
  sortsKanban: new Map<string, SortDef[]>(),

  staffing: null,
  staffingError: null,
  staffingChecklistError: null,
  staffingErrorValidation: null,
  isLoadingStaffing: false,
  isStaffingLoaded: false,
  isCreatingStaffing: false,
  createdStaffingData: null,
  isUpdatingStaffing: false,
  isStaffingUpdated: false,
  isStaffingCreated: false,
  updatedStaffingData: null,
  isDeletingStaffing: false,
  deletedStaffingSucceeded: false,
  deletedStaffingData: null,

  staffingToPreviewMod: null,
  staffingPreviewOpenState: false,

  statuses: [],
  isLoadingStatuses: false,
  isStatusesLoaded: false,
};

const _state = JSON.parse(JSON.stringify(state));
export const defaultState = (): StaffingState => {
  {
    return JSON.parse(JSON.stringify(_state));
  }
};
