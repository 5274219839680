import { GetterTree } from 'vuex';
import { RootState } from '@/store';
import { State } from './state';
import {UserExport, User, UserImport} from '@/types/index';

export type Getters = {
  StateUsers(state: State): User[],
  StateIsLoadingUsers(state: State): boolean,
  StateIsUsersLoaded(state: State): boolean,

  StateUserExportGenerating: (state: State) => Boolean,
  StateUserExportGenerationDone: (state: State) => Boolean,
  StateUserExportResults: (state: State) => Object | null,

  StateUserImport: (state: State) => UserImport | null,
  StateUserImportIsImporting: (state: State) => Boolean,
  StateUserImportDone: (state: State) => Boolean,
  StateUserImportResults: (state: State) => Object | null,

  StateUserExportsLoading: (state: State) => Boolean,
  StateUserExportsLoaded: (state: State) => Boolean,
  StateUserExports: (state: State) => UserExport[]
}

export const getters: GetterTree<State, RootState> & Getters = {
  StateUsers: state => state.users,
  StateIsLoadingUsers: state => state.isLoadingUsers,
  StateIsUsersLoaded: state => state.isUsersLoaded,

  StateUserImport: (state: State) => state.userImport,
  StateUserImportIsImporting: (state: State) => state.userImportIsImporting,
  StateUserImportDone: (state: State) => state.userImportIsImporting,
  StateUserImportResults: (state: State) => state.userImportResults,

  StateUserExportGenerating: (state: State) => state.userExportGenerating,
  StateUserExportGenerationDone: (state: State) => state.userExportGenerationDone,
  StateUserExportResults: (state: State) => state.userExportResults,

  StateUserExportsLoading: (state: State) => state.userExportsLoading,
  StateUserExportsLoaded: (state: State) => state.userExportsLoaded,
  StateUserExports: (state: State) => state.userExports
};
