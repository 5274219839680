import { ActionContext, ActionTree } from "vuex";
import { RootState } from "@/store";
import { omit } from "lodash";
import { ServiceCatalogueActionTypes } from "./action-types";
import {
  ServiceCatalogueCategory,
  ServiceCataloguePriority,
  // ServiceCatalogueCategoryTree,
  // ServiceCatalogueListFields,
  // ServiceCatalogueImport,
  // ServiceCatalogueCategoryServiceCatalogueFieldRelation,
  // ServiceCatalogueExport
} from "@/types/index";
import { ServiceCatalogueState as State } from "./state";
import { ServiceCatalogueMutationTypes } from "./mutation-types";
// import axios, { AxiosRequestConfig } from "axios";
import { HttpClient as http, AxiosError, AxiosResponse } from "@/util/ZebritAxios";
import { Config } from "../../../config";
// import ZebritAxios from "../../../util/ZebritAxios";
import {
  ServiceCatalogueItem,
  IGetServiceCatalogueItem,
  FieldDef,
  SortDef,
  FilterDef,
  ServiceCatalogueItemListFilters,
  ServiceCatalogueItemListFields
  // ServiceCatalogueField,
} from "@/types/index";
import { Mutations } from "./mutations";
// import { ServiceCatalogueMutationTypes } from "./mutation-types";
// import { getters as ServiceCatalogueGetters } from './getters';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [ServiceCatalogueActionTypes.GETSERVICECATALOGUEITEMS_ACTION](
    { commit }: AugmentedActionContext,
    payload: {
      page: number;
      page_size: number;
      category: string | null;
      priority: string | null;
      search: string | null;
    }
  ): void;

  [ServiceCatalogueActionTypes.GETSERVICECATALOGUEITEM_ACTION](
    { commit }: AugmentedActionContext,
    payload: IGetServiceCatalogueItem
  ): void;
  [ServiceCatalogueActionTypes.SAVESERVICECATALOGUEITEM_ACTION](
    { commit }: AugmentedActionContext,
    payload: ServiceCatalogueItem
  ): void;
  [ServiceCatalogueActionTypes.SETSERVICECATALOGUEITEM_ACTION](
    { commit }: AugmentedActionContext,
    payload: ServiceCatalogueItem | null
  ): void;
  [ServiceCatalogueActionTypes.DELETESERVICECATALOGUEITEM_ACTION](
    { commit }: AugmentedActionContext,
    payload: string
  ): void;

  [ServiceCatalogueActionTypes.GET_SERVICECATALOGUE_CATEGORIES_ACTION]({
    commit,
  }: AugmentedActionContext): void;
  [ServiceCatalogueActionTypes.SAVE_SERVICECATALOGUE_CATEGORIES_ACTION](
    { commit }: AugmentedActionContext,
    payload: ServiceCatalogueCategory[]
  ): void;

  [ServiceCatalogueActionTypes.GET_SERVICECATALOGUE_PRIORITIES_ACTION]({
    commit,
  }: AugmentedActionContext): void;
  [ServiceCatalogueActionTypes.SAVE_SERVICECATALOGUE_PRIORITIES_ACTION](
      { commit }: AugmentedActionContext,
      payload: ServiceCataloguePriority[]
  ): void;

  [ServiceCatalogueActionTypes.SAVE_SERVICECATALOGUE_CATEGORY_ACTION](
    { commit }: AugmentedActionContext,
    payload: ServiceCatalogueCategory
  ): void;
  [ServiceCatalogueActionTypes.DELETE_SERVICECATALOGUE_CATEGORY_ACTION](
    { commit }: AugmentedActionContext,
    payload: string
  ): void;

  [ServiceCatalogueActionTypes.SAVE_SERVICECATALOGUE_CATEGORY_TREE_ACTION](
    { commit }: AugmentedActionContext,
    payload: ServiceCatalogueCategory[]
  ): void;

  [ServiceCatalogueActionTypes.SAVE_SERVICECATALOGUE_PRIORITY_ACTION](
      { commit }: AugmentedActionContext,
      payload: ServiceCataloguePriority
  ): void;
  [ServiceCatalogueActionTypes.DELETE_SERVICECATALOGUE_PRIORITY_ACTION](
      { commit }: AugmentedActionContext,
      payload: string
  ): void;

  [ServiceCatalogueActionTypes.SET_PAGE](
    { commit }: AugmentedActionContext,
    payload: number
  ): void;
  [ServiceCatalogueActionTypes.SET_PAGESIZE](
    { commit }: AugmentedActionContext,
    payload: number
  ): void;
  [ServiceCatalogueActionTypes.SET_TOTAL](
    { commit }: AugmentedActionContext,
    payload: number
  ): void;
  [ServiceCatalogueActionTypes.SET_SEARCH](
    { commit }: AugmentedActionContext,
    payload: string | null
  ): void;
  [ServiceCatalogueActionTypes.SET_FILTER](
    { commit }: AugmentedActionContext,
    payload: FilterDef[]
  ): void;
  [ServiceCatalogueActionTypes.SET_AVAILABLEFIELDS](
    { commit }: AugmentedActionContext,
    payload: FieldDef[]
  ): void;
  [ServiceCatalogueActionTypes.SET_SORTS](
    { commit }: AugmentedActionContext,
    payload: SortDef[]
  ): void;

  [ServiceCatalogueActionTypes.SET_SERVICECATALOGUEITEM_TO_PREVIEW_MOD_ACTION](
    { commit }: AugmentedActionContext,
    payload: string | null
  ): void;

  [ServiceCatalogueActionTypes.SET_SERVICECATALOGUEITEM_PREVIEW_OPEN_STATE_ACTION](
    { commit }: AugmentedActionContext,
    payload: boolean
  ): void;
}

// const getters = mapGetters({
//   page: "asset/StatePage",
//   page_size: "asset/StatePageSize",
//   total: "asset/StateTotal",
//   search: "asset/StateSearch",
//   filter: "asset/StateFilter"
// });

export const actions: ActionTree<State, RootState> & Actions = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [ServiceCatalogueActionTypes.GETSERVICECATALOGUEITEMS_ACTION](
    { commit, getters }: AugmentedActionContext // payload: { //   // page: number; //   // page_size: number, //   category: string | null; //   // search: string | null
  ) // }
  {
    commit(ServiceCatalogueMutationTypes.SET_IS_LOADING_SERVICECATALOGUEITEMS, true);
    // debugger; // eslint-disable-line
    const page = getters.StatePage; // ServiceCatalogueGetters.StatePage() || 1;
    const page_size = getters.StatePageSize; // ServiceCatalogueGetters.StatePageSize || 10;
    const search = getters.StateSearch; // ServiceCatalogueGetters.StateSearch || 1;
    const filter = getters.StateFilter;
    const sorts = getters.StateSorts;

    // debugger; // eslint-disable-line
    const params: any = {
      $page: page, //payload && payload.page ? payload.page : 1,
      $limit: page_size, //payload && payload.page_size ? payload.page_size : 10,
      // $text: search, //payload && payload.search ? payload.search : null
    };

    if (search) {
      params.$term = search;
      const searchFields = ServiceCatalogueItemListFields.filter((af) => af.searchable === true).map(
        (af) => af.id
      );
      params["$searchFields"] = searchFields; // `[${searchFields.join(',')}]`;//searchFields;
    }

    ServiceCatalogueItemListFilters.forEach((f) => {
      const _f = filter.find((__f: FilterDef) => __f.id == f.id);
      if (_f && _f.value != f.value) {
        // debugger; // eslint-disable-line
        if (typeof _f.value == "string") {
          params[_f.queryParam] = _f.value;
        } else {
          // debugger; // eslint-disable-line
          params[_f.queryParam] = _f.value._id;
        }
      }
    });

    if (sorts && sorts.length > 0) {
      params["$sort"] = (sorts[0].direction == "desc" ? "-" : "") + sorts[0].field.id;
    }

    await http
      .get(
        // Config.api.serviceCatalogue.item +
        //   "?$embed=category&$embed=owner&$embed=manufacturer&$embed=model",
        Config.api.serviceCatalogue.item,
        params,
        {
          paramsSerializer: {
            indexes: null,
          },
        }
      )
      .then((res: AxiosResponse) => {
        // res.data.docs.forEach(a => {
        //   a.ca
        // });

        res.data.docs.forEach((doc: any) => {
          if (doc.categoryName) doc.category = doc.categoryName;

          if (doc.priorityName) doc.priority = doc.priorityName;

          if (doc.manufacturerName) doc.manufacturer = doc.manufacturerName;
          if (doc.manufacturerText) doc.manufacturer = doc.manufacturerText;

          if (doc.modelName) doc.model = doc.modelName;

          if (doc.owner) doc.owner = `${doc.ownerFirstName} ${doc.ownerLastName}`;

          // if (doc.purchaseDate) doc.purchaseDate = new Date(doc.purchaseDate);
          // if (doc.warrantyExpireDate) doc.warrantyExpireDate = new Date(doc.warrantyExpireDate);
        });

        commit(ServiceCatalogueMutationTypes.SET_SERVICECATALOGUEITEMS, res.data.docs);
        commit(ServiceCatalogueMutationTypes.SET_PAGE, res.data.pages.current);
        commit(ServiceCatalogueMutationTypes.SET_PAGESIZE, res.data.items.limit);
        commit(ServiceCatalogueMutationTypes.SET_TOTAL, res.data.items.total);
        // commit(ServiceCatalogueMutationTypes.SET_STARTINDEX, res.data.startIndex);

        commit(ServiceCatalogueMutationTypes.SET_IS_LOADING_SERVICECATALOGUEITEMS, false);
      })
      .catch((err: AxiosError) => {
        console.log("error", err);
        commit(ServiceCatalogueMutationTypes.SET_IS_LOADING_SERVICECATALOGUEITEMS, false);
      });
  },

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [ServiceCatalogueActionTypes.GETSERVICECATALOGUEITEM_ACTION](
    { commit }: AugmentedActionContext,
    payload: IGetServiceCatalogueItem
  ) {
    //commit(ServiceCatalogueMutationTypes.SET_IS_LOADING_SERVICECATALOGUE, true);
    commit(ServiceCatalogueMutationTypes.SET_IS_LOADING_SERVICECATALOGUEITEM, true);
    commit(ServiceCatalogueMutationTypes.SET_IS_SERVICECATALOGUEITEM_LOADED, false);
    // console.log(Config);
    // console.log(Config.api);
    // console.log(Config.api.asset);
    // console.log(Config.api.asset.get);
    // debugger; // eslint-disable-line
    await http
      .get(Config.api.serviceCatalogue.getItem.replace("{id}", payload.id.toString())) // `api/asset/${id}`)
      .then((res: AxiosResponse) => {
        // if (res.data.purchaseDate) res.data.purchaseDate = new Date(res.data.purchaseDate);
        // if (res.data.warrantyExpireDate) res.data.warrantyExpireDate = new Date(res.data.warrantyExpireDate);
        commit(ServiceCatalogueMutationTypes.SET_SERVICECATALOGUEITEM, res.data);
        commit(ServiceCatalogueMutationTypes.SET_IS_LOADING_SERVICECATALOGUEITEM, false);
        commit(ServiceCatalogueMutationTypes.SET_IS_SERVICECATALOGUEITEM_LOADED, true);
      })
      .catch((err: AxiosError) => {
        console.log("error", err);
        commit(ServiceCatalogueMutationTypes.SET_IS_LOADING_SERVICECATALOGUEITEM, false);
        commit(ServiceCatalogueMutationTypes.SET_IS_SERVICECATALOGUEITEM_LOADED, false);
      });
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [ServiceCatalogueActionTypes.SAVESERVICECATALOGUEITEM_ACTION](
    { commit, dispatch }: AugmentedActionContext,
    payload: ServiceCatalogueItem
  ) {
    // debugger; // eslint-disable-line

    const actionType = payload._id
      ? ServiceCatalogueMutationTypes.SET_IS_UPDATING_SERVICECATALOGUEITEM
      : ServiceCatalogueMutationTypes.SET_IS_CREATING_SERVICECATALOGUEITEM;
    commit(actionType, true);

    const actionFinishedType = payload._id
      ? ServiceCatalogueMutationTypes.SET_IS_SERVICECATALOGUEITEM_UPDATED
      : ServiceCatalogueMutationTypes.SET_IS_SERVICECATALOGUEITEM_CREATED;
    commit(actionFinishedType, false);

    // await http.put(`api/assets/${payload.id}`, payload)
    //   .then(res => {
    //     commit(ServiceCatalogueMutationTypes.SET_SERVICECATALOGUE, res.data)
    //     commit(actionType, false);
    //     dispatch(ServiceCatalogueActionTypes.GETSERVICECATALOGUES_ACTION);
    //   })
    //   .catch(err => {
    //     console.log('error', err);
    //     commit(actionType, false);
    //   })
    // const axiosConfig: AxiosRequestConfig = {
    //   method: payload.id ? "PUT" : "POST",
    //   url: Config.api.assets.assets + (payload.id ? `/${payload.id}` : ""),
    //   data: payload,
    // };

    let _payload = JSON.parse(JSON.stringify(payload));
    // _payload.owner = _payload.ownerId;
    _payload = Object.fromEntries(
      Object.entries(_payload).filter(([id, v]) => v !== null)
    );
    _payload = Object.fromEntries(Object.entries(_payload).filter(([id, v]) => v !== ""));
    _payload = Object.fromEntries(
      Object.entries(_payload).filter(([id, v]) => v !== undefined)
    );

    _payload = omit(_payload, [
      "categoryName",
      "subCategoryName",
      "priorityName",
      "documents",
      "manufacturerName",
      "manufacturerText",
      "modelText",
      "ownerFirstName",
      "ownerLastName",
      "ownerAvatar",
      "ownerAvatarUrl",
      "pictureUrl",
      "categoryIcon",
      "subCategoryIcon",
      "categoryIconUrl",
      "subCategoryIconUrl",
    ]);
    // debugger; // eslint-disable-line
    await http
      .request(
        payload._id ? "PUT" : "POST",
        Config.api.serviceCatalogue.item + (payload._id ? `/${payload._id}` : ""),
        _payload
      )
      .then((res: any) => {
        // debugger; // eslint-disable-line
        if (res instanceof AxiosError) {
          const _err = res as AxiosError;
          const errorMessage: any = res?.response?.data?.message;
          if (errorMessage) {
            commit(
              ServiceCatalogueMutationTypes.SET_SERVICECATALOGUEITEM_ERROR,
              errorMessage
            );
          } else {
            commit(
              ServiceCatalogueMutationTypes.SET_SERVICECATALOGUEITEM_ERROR,
              _err.message
            );
          }
          const validationError: any = res?.response?.data?.validation;
          if (validationError) {
            commit(
              ServiceCatalogueMutationTypes.SET_SERVICECATALOGUEITEM_ERROR_VALIDATION,
              validationError
            );
          }
          commit(actionType, false);
          commit(actionFinishedType, true);
          // dispatch(ServiceCatalogueActionTypes.GETSERVICECATALOGUES_ACTION);
        } else {
          // const _res = res as AxiosResponse;
          commit(ServiceCatalogueMutationTypes.SET_SERVICECATALOGUEITEM_ERROR, null);
          commit(
            ServiceCatalogueMutationTypes.SET_SERVICECATALOGUEITEM_ERROR_VALIDATION,
            null
          );
          commit(ServiceCatalogueMutationTypes.SET_SERVICECATALOGUEITEM, res.data);
          commit(actionType, false);
          commit(actionFinishedType, true);
          dispatch(ServiceCatalogueActionTypes.GETSERVICECATALOGUEITEMS_ACTION);
        }
      })
      .catch((err: any) => {
        // debugger; // eslint-disable-line
        console.log("error", err);
        if (err?.data?.message) {
          const errorMessage: any = err?.data?.message;

          commit(
            ServiceCatalogueMutationTypes.SET_SERVICECATALOGUEITEM_ERROR,
            errorMessage
          );
        } else {
          commit(ServiceCatalogueMutationTypes.SET_SERVICECATALOGUEITEM_ERROR, null);
        }

        commit(actionType, false);
        commit(actionFinishedType, false);
      });
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [ServiceCatalogueActionTypes.SETSERVICECATALOGUEITEM_ACTION](
    { commit }: AugmentedActionContext,
    payload: ServiceCatalogueItem | null
  ) {
    // const actionType = (payload.asset.id) ?
    //   ServiceCatalogueMutationTypes.SET_IS_CREATING_SERVICECATALOGUE
    //   :
    //   ServiceCatalogueMutationTypes.SET_IS_UPDATING_SERVICECATALOGUE;
    // commit(actionType, true);

    commit(ServiceCatalogueMutationTypes.SET_SERVICECATALOGUEITEM, payload);
    // commit(actionType, false);
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [ServiceCatalogueActionTypes.DELETESERVICECATALOGUEITEM_ACTION](
    { commit, dispatch }: AugmentedActionContext,
    payload: string
  ) {
    commit(ServiceCatalogueMutationTypes.SET_IS_DELETING_SERVICECATALOGUEITEM, true);
    commit(ServiceCatalogueMutationTypes.SET_DELETED_SERVICECATALOGUEITEM_SUCCEEDED, false);
    // const axiosConfig: AxiosRequestConfig = {
    //   method: "DELETE",
    //   url: Config.api.asset.delete.replace("{id}", `${payload.id}`), // + `/${payload.id}`,
    //   data: payload,
    // };
    await http
      .request(
        "DELETE",
        Config.api.serviceCatalogue.deleteItem.replace("{id}", `${payload}`) // + `/${payload.id}`,
      )
      .then(() => {
        commit(ServiceCatalogueMutationTypes.SET_SERVICECATALOGUEITEM, null);
        commit(ServiceCatalogueMutationTypes.SET_IS_DELETING_SERVICECATALOGUEITEM, false);
        commit(ServiceCatalogueMutationTypes.SET_DELETED_SERVICECATALOGUEITEM_SUCCEEDED, true);
      })
      .catch((err: AxiosError) => {
        console.log("error", err);
        commit(ServiceCatalogueMutationTypes.SET_IS_DELETING_SERVICECATALOGUEITEM, false);
        commit(ServiceCatalogueMutationTypes.SET_DELETED_SERVICECATALOGUEITEM_SUCCEEDED, false);
      });
  },

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [ServiceCatalogueActionTypes.GET_SERVICECATALOGUE_CATEGORIES_ACTION]({
    commit,
  }: AugmentedActionContext) {
    commit(
      ServiceCatalogueMutationTypes.SET_IS_LOADING_SERVICECATALOGUE_CATEGORIES,
      true
    );
    await http
      .get(Config.api.serviceCatalogue.category)
      .then((res: AxiosResponse) => {
        // commit(ServiceCatalogueMutationTypes.SET_CATEGORIES, res.data);
        commit(
          ServiceCatalogueMutationTypes.SET_SERVICECATALOGUE_CATEGORIES,
          res.data.docs
        );
        commit(
          ServiceCatalogueMutationTypes.SET_IS_LOADING_SERVICECATALOGUE_CATEGORIES,
          false
        );
        commit(
          ServiceCatalogueMutationTypes.SET_IS_SERVICECATALOGUE_CATEGORIES_LOADED,
          true
        );
      })
      .catch((err: AxiosError) => {
        console.log("error", err);
        commit(ServiceCatalogueMutationTypes.SET_SERVICECATALOGUE_CATEGORIES, []);
        commit(
          ServiceCatalogueMutationTypes.SET_IS_LOADING_SERVICECATALOGUE_CATEGORIES,
          false
        );
        commit(
          ServiceCatalogueMutationTypes.SET_IS_SERVICECATALOGUE_CATEGORIES_LOADED,
          false
        );
      });
  },

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [ServiceCatalogueActionTypes.GET_SERVICECATALOGUE_PRIORITIES_ACTION]({
    commit,
  }: AugmentedActionContext) {
    commit(
        ServiceCatalogueMutationTypes.SET_IS_LOADING_SERVICECATALOGUE_PRIORITIES,
        true
    );
    await http
        .get(Config.api.serviceCatalogue.priority)
        .then((res: AxiosResponse) => {
          // commit(ServiceCatalogueMutationTypes.SET_PRIORITIES, res.data);
          commit(
              ServiceCatalogueMutationTypes.SET_SERVICECATALOGUE_PRIORITIES,
              res.data.docs
          );
          commit(
              ServiceCatalogueMutationTypes.SET_IS_LOADING_SERVICECATALOGUE_PRIORITIES,
              false
          );
          commit(
              ServiceCatalogueMutationTypes.SET_IS_SERVICECATALOGUE_PRIORITIES_LOADED,
              true
          );
        })
        .catch((err: AxiosError) => {
          console.log("error", err);
          commit(ServiceCatalogueMutationTypes.SET_SERVICECATALOGUE_PRIORITIES, []);
          commit(
              ServiceCatalogueMutationTypes.SET_IS_LOADING_SERVICECATALOGUE_PRIORITIES,
              false
          );
          commit(
              ServiceCatalogueMutationTypes.SET_IS_SERVICECATALOGUE_PRIORITIES_LOADED,
              false
          );
        });
  },

  async [ServiceCatalogueActionTypes.SAVE_SERVICECATALOGUE_CATEGORIES_ACTION](
    { commit, dispatch }: AugmentedActionContext,
    payload: ServiceCatalogueCategory[]
  ) {
    console.log("SAVE_SERVICECATALOGUE_CATEGORIES_ACTION");
    console.log(payload);

    commit(ServiceCatalogueMutationTypes.SET_IS_SAVING_SERVICECATALOGUE_CATEGORIES, true);
    // debugger; // eslint-disable-line
    await http
      .put(Config.api.serviceCatalogue.category, payload)
      .then((response: AxiosResponse) => {
        commit(
          ServiceCatalogueMutationTypes.SET_SERVICECATALOGUE_CATEGORIES,
          response.data
        );
        commit(
          ServiceCatalogueMutationTypes.SET_IS_SAVING_SERVICECATALOGUE_CATEGORIES,
          false
        );
        dispatch(ServiceCatalogueActionTypes.GET_SERVICECATALOGUE_CATEGORIES_ACTION);
      })
      .catch((err: AxiosError) => {
        commit(
          ServiceCatalogueMutationTypes.SET_IS_SAVING_SERVICECATALOGUE_CATEGORIES,
          false
        );
        console.log("error", err);
      });
  },

  async [ServiceCatalogueActionTypes.SAVE_SERVICECATALOGUE_PRIORITIES_ACTION](
      { commit, dispatch }: AugmentedActionContext,
      payload: ServiceCataloguePriority[]
  ) {
    console.log("SAVE_SERVICECATALOGUE_PRIORITIES_ACTION");
    console.log(payload);

    commit(ServiceCatalogueMutationTypes.SET_IS_SAVING_SERVICECATALOGUE_PRIORITIES, true);
    // debugger; // eslint-disable-line
    await http
        .put(Config.api.serviceCatalogue.priority, payload)
        .then((response: AxiosResponse) => {
          commit(
              ServiceCatalogueMutationTypes.SET_SERVICECATALOGUE_PRIORITIES,
              response.data
          );
          commit(
              ServiceCatalogueMutationTypes.SET_IS_SAVING_SERVICECATALOGUE_PRIORITIES,
              false
          );
          dispatch(ServiceCatalogueActionTypes.GET_SERVICECATALOGUE_PRIORITIES_ACTION);
        })
        .catch((err: AxiosError) => {
          commit(
              ServiceCatalogueMutationTypes.SET_IS_SAVING_SERVICECATALOGUE_PRIORITIES,
              false
          );
          console.log("error", err);
        });
  },

  async [ServiceCatalogueActionTypes.SAVE_SERVICECATALOGUE_CATEGORY_ACTION](
    { commit }: AugmentedActionContext,
    payload: ServiceCatalogueCategory
  ) {
    // console.log("SAVE_CATEGORY_ACTION");
    // console.log(payload);

    commit(ServiceCatalogueMutationTypes.SET_IS_SAVING_SERVICECATALOGUE_CATEGORY, true);
    commit(ServiceCatalogueMutationTypes.SET_IS_SERVICECATALOGUE_CATEGORY_SAVED, false);
    commit(ServiceCatalogueMutationTypes.SET_SERVICECATALOGUE_CATEGORY_SAVE_ERROR, null);
    // debugger; // eslint-disable-line
    const isNew = payload._id === null || payload._id == -1;

    let _payload = JSON.parse(JSON.stringify(payload));
    // _payload.owner = _payload.ownerId;
    // _payload = Object.fromEntries(Object.entries(_payload).filter(([_, v]) => v !== null))
    // _payload = Object.fromEntries(Object.entries(_payload).filter(([_, v]) => v !== ''))
    // _payload = Object.fromEntries(Object.entries(_payload).filter(([_, v]) => v !== undefined))
    if (isNew) _payload = omit(_payload, ["_id"]);

    _payload = omit(_payload, [
      "createdAt",
      "createdBy",
      "updatedAt",
      "updatedBy",
      "subCategories",
      "iconUrl",
      "expanded",
    ]);

    await http
      .request(
        isNew ? "POST" : "PUT",
        Config.api.serviceCatalogue.category + (!isNew ? `/${_payload._id}` : ""),
        isNew ? [_payload] : _payload
      )
      // .put(Config.api.serviceCatalogue.category, payload)
      .then(() => {
        // commit(ServiceCatalogueMutationTypes.SET_CATEGORIES, response.data);
        commit(
          ServiceCatalogueMutationTypes.SET_IS_SAVING_SERVICECATALOGUE_CATEGORY,
          false
        );
        commit(
          ServiceCatalogueMutationTypes.SET_IS_SERVICECATALOGUE_CATEGORY_SAVED,
          true
        );
        commit(
          ServiceCatalogueMutationTypes.SET_SERVICECATALOGUE_CATEGORY_SAVE_ERROR,
          null
        );
        // dispatch(ServiceCatalogueActionTypes.GET_CATEGORIES_ACTION);
      })
      .catch((err: AxiosError) => {
        commit(
          ServiceCatalogueMutationTypes.SET_IS_SAVING_SERVICECATALOGUE_CATEGORY,
          false
        );
        commit(
          ServiceCatalogueMutationTypes.SET_IS_SERVICECATALOGUE_CATEGORY_SAVED,
          false
        );
        commit(
          ServiceCatalogueMutationTypes.SET_SERVICECATALOGUE_CATEGORY_SAVE_ERROR,
          err.message
        );
        console.log("error", err);
      });
  },

  async [ServiceCatalogueActionTypes.SAVE_SERVICECATALOGUE_PRIORITY_ACTION](
      { commit }: AugmentedActionContext,
      payload: ServiceCataloguePriority
  ) {
    // console.log("SAVE_PRIORITY_ACTION");
    // console.log(payload);

    commit(ServiceCatalogueMutationTypes.SET_IS_SAVING_SERVICECATALOGUE_PRIORITY, true);
    commit(ServiceCatalogueMutationTypes.SET_IS_SERVICECATALOGUE_PRIORITY_SAVED, false);
    commit(ServiceCatalogueMutationTypes.SET_SERVICECATALOGUE_PRIORITY_SAVE_ERROR, null);
    // debugger; // eslint-disable-line
    const isNew = payload._id === null || payload._id == -1;

    let _payload = JSON.parse(JSON.stringify(payload));
    // _payload.owner = _payload.ownerId;
    // _payload = Object.fromEntries(Object.entries(_payload).filter(([_, v]) => v !== null))
    // _payload = Object.fromEntries(Object.entries(_payload).filter(([_, v]) => v !== ''))
    // _payload = Object.fromEntries(Object.entries(_payload).filter(([_, v]) => v !== undefined))
    if (isNew) _payload = omit(_payload, ["_id"]);

    _payload = omit(_payload, [
      "createdAt",
      "createdBy",
      "updatedAt",
      "updatedBy",
      "expanded",
    ]);

    await http
        .request(
            isNew ? "POST" : "PUT",
            Config.api.serviceCatalogue.priority + (!isNew ? `/${_payload._id}` : ""),
            isNew ? [_payload] : _payload
        )
        // .put(Config.api.serviceCatalogue.priority, payload)
        .then(() => {
          // commit(ServiceCatalogueMutationTypes.SET_PRIORITIES, response.data);
          commit(
              ServiceCatalogueMutationTypes.SET_IS_SAVING_SERVICECATALOGUE_PRIORITY,
              false
          );
          commit(
              ServiceCatalogueMutationTypes.SET_IS_SERVICECATALOGUE_PRIORITY_SAVED,
              true
          );
          commit(
              ServiceCatalogueMutationTypes.SET_SERVICECATALOGUE_PRIORITY_SAVE_ERROR,
              null
          );
          // dispatch(ServiceCatalogueActionTypes.GET_PRIORITIES_ACTION);
        })
        .catch((err: AxiosError) => {
          commit(
              ServiceCatalogueMutationTypes.SET_IS_SAVING_SERVICECATALOGUE_PRIORITY,
              false
          );
          commit(
              ServiceCatalogueMutationTypes.SET_IS_SERVICECATALOGUE_PRIORITY_SAVED,
              false
          );
          commit(
              ServiceCatalogueMutationTypes.SET_SERVICECATALOGUE_PRIORITY_SAVE_ERROR,
              err.message
          );
          console.log("error", err);
        });
  },

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [ServiceCatalogueActionTypes.DELETE_SERVICECATALOGUE_CATEGORY_ACTION](
    { commit, dispatch }: AugmentedActionContext,
    payload: string
  ) {
    commit(ServiceCatalogueMutationTypes.SET_IS_DELETING_SERVICECATALOGUE_CATEGORY, true);
    // const axiosConfig: AxiosRequestConfig = {
    //   method: "DELETE",
    //   url: Config.api.asset.delete.replace("{id}", `${payload.id}`), // + `/${payload.id}`,
    //   data: payload,
    // };
    await http
      .request(
        "DELETE",
        Config.api.serviceCatalogue.category, // + `/${payload.id}`,
        [payload]
      )
      .then(() => {
        commit(ServiceCatalogueMutationTypes.SET_SERVICECATALOGUEITEM, null);
        commit(ServiceCatalogueMutationTypes.SET_IS_DELETING_SERVICECATALOGUEITEM, false);
        dispatch(ServiceCatalogueActionTypes.GETSERVICECATALOGUEITEMS_ACTION);
      })
      .catch((err: AxiosError) => {
        console.log("error", err);
        commit(ServiceCatalogueMutationTypes.SET_IS_DELETING_SERVICECATALOGUEITEM, false);
      });
  },

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [ServiceCatalogueActionTypes.DELETE_SERVICECATALOGUE_PRIORITY_ACTION](
      { commit, dispatch }: AugmentedActionContext,
      payload: string
  ) {
    commit(ServiceCatalogueMutationTypes.SET_IS_DELETING_SERVICECATALOGUE_PRIORITY, true);
    // const axiosConfig: AxiosRequestConfig = {
    //   method: "DELETE",
    //   url: Config.api.asset.delete.replace("{id}", `${payload.id}`), // + `/${payload.id}`,
    //   data: payload,
    // };
    await http
        .request(
            "DELETE",
            Config.api.serviceCatalogue.priority, // + `/${payload.id}`,
            [payload]
        )
        .then(() => {
          commit(ServiceCatalogueMutationTypes.SET_SERVICECATALOGUEITEM, null);
          commit(ServiceCatalogueMutationTypes.SET_IS_DELETING_SERVICECATALOGUEITEM, false);
          dispatch(ServiceCatalogueActionTypes.GETSERVICECATALOGUEITEMS_ACTION);
        })
        .catch((err: AxiosError) => {
          console.log("error", err);
          commit(ServiceCatalogueMutationTypes.SET_IS_DELETING_SERVICECATALOGUEITEM, false);
        });
  },

  async [ServiceCatalogueActionTypes.SAVE_SERVICECATALOGUE_CATEGORY_TREE_ACTION](
    { commit }: AugmentedActionContext,
    payload: ServiceCatalogueCategory[]
  ) {
    console.log("SAVE_CATEGORY_TREE_ACTION");
    console.log(payload);

    commit(ServiceCatalogueMutationTypes.SET_IS_SAVING_SERVICECATALOGUE_CATEGORY, true);
    // debugger; // eslint-disable-line
    // const isNew = payload._id === null || payload._id == -1;

    const _payload = JSON.parse(JSON.stringify(payload));
    // _payload.owner = _payload.ownerId;
    // _payload = Object.fromEntries(Object.entries(_payload).filter(([_, v]) => v !== null))
    // _payload = Object.fromEntries(Object.entries(_payload).filter(([_, v]) => v !== ''))
    // _payload = Object.fromEntries(Object.entries(_payload).filter(([_, v]) => v !== undefined))
    // if (isNew) _payload = omit(_payload, ["_id"]);

    // _payload = omit(_payload, [
    //   "createdAt",
    //   "createdBy",
    //   "updatedAt",
    //   "updatedBy",
    //   "subCategories",
    //   "updatedAt",
    //   "iconUrl",
    //   "expanded"
    // ]);

    await http
      .request("PUT", Config.api.serviceCatalogue.category, _payload)
      // .put(Config.api.assets.assetsCategory, payload)
      .then(() => {
        // commit(ServiceCatalogueMutationTypes.SET_CATEGORIES, response.data);
        commit(
          ServiceCatalogueMutationTypes.SET_IS_SAVING_SERVICECATALOGUE_CATEGORY,
          false
        );
        // dispatch(ServiceCatalogueActionTypes.GET_CATEGORIES_ACTION);
      })
      .catch((err: AxiosError) => {
        commit(
          ServiceCatalogueMutationTypes.SET_IS_SAVING_SERVICECATALOGUE_CATEGORY,
          false
        );
        console.log("error", err);
      });
  },

  // [ServiceCatalogueActionTypes.GET_FIELDS_ACTION]({ commit }: AugmentedActionContext): void;
  // async [ServiceCatalogueActionTypes.GET_FIELDS_ACTION]({ commit }: AugmentedActionContext) {
  //   commit(ServiceCatalogueMutationTypes.SET_IS_LOADING_FIELDS, true);
  //   await http
  //     .get(Config.api.assets.assetsFields)
  //     .then((res: AxiosResponse) => {
  //       // commit(ServiceCatalogueMutationTypes.SET_FIELDS, res.data);
  //       // debugger; // eslint-disable-line
  //       commit(ServiceCatalogueMutationTypes.SET_FIELDS, res.data.docs);
  //       // debugger; // eslint-disable-line
  //       commit(ServiceCatalogueMutationTypes.SET_FIELDS_PAGE, res.data.pages.current);
  //       commit(ServiceCatalogueMutationTypes.SET_FIELDS_PAGESIZE, res.data.items.limit);
  //       commit(ServiceCatalogueMutationTypes.SET_FIELDS_TOTAL, res.data.items.total);
  //       commit(ServiceCatalogueMutationTypes.SET_IS_LOADING_FIELDS, false);
  //       commit(ServiceCatalogueMutationTypes.SET_IS_FIELDS_LOADED, true);
  //     })
  //     .catch((err: AxiosError) => {
  //       console.log("error", err);
  //       commit(ServiceCatalogueMutationTypes.SET_FIELDS, []);
  //       commit(ServiceCatalogueMutationTypes.SET_IS_LOADING_FIELDS, false);
  //       commit(ServiceCatalogueMutationTypes.SET_IS_FIELDS_LOADED, false);
  //     });
  // },

  [ServiceCatalogueActionTypes.SET_PAGE](
    { commit }: AugmentedActionContext,
    payload: number
  ) {
    commit(ServiceCatalogueMutationTypes.SET_PAGE, payload);
  },

  [ServiceCatalogueActionTypes.SET_PAGESIZE](
    { commit }: AugmentedActionContext,
    payload: number
  ) {
    commit(ServiceCatalogueMutationTypes.SET_PAGESIZE, payload);
  },

  [ServiceCatalogueActionTypes.SET_TOTAL](
    { commit }: AugmentedActionContext,
    payload: number
  ) {
    commit(ServiceCatalogueMutationTypes.SET_TOTAL, payload);
  },

  [ServiceCatalogueActionTypes.SET_SEARCH](
    { commit }: AugmentedActionContext,
    payload: string | null
  ) {
    commit(ServiceCatalogueMutationTypes.SET_SEARCH, payload);
  },

  [ServiceCatalogueActionTypes.SET_FILTER](
    { commit }: AugmentedActionContext,
    payload: FilterDef[]
  ) {
    commit(ServiceCatalogueMutationTypes.SET_FILTER, payload);
  },

  [ServiceCatalogueActionTypes.SET_AVAILABLEFIELDS](
    { commit }: AugmentedActionContext,
    payload: FieldDef[]
  ) {
    commit(ServiceCatalogueMutationTypes.SET_AVAILABLEFIELDS, payload);
  },

  [ServiceCatalogueActionTypes.SET_SORTS](
    { commit }: AugmentedActionContext,
    payload: SortDef[]
  ) {
    commit(ServiceCatalogueMutationTypes.SET_SORTS, payload);
  },

  [ServiceCatalogueActionTypes.SET_SERVICECATALOGUEITEM_TO_PREVIEW_MOD_ACTION](
    { commit }: AugmentedActionContext,
    payload: string | null
  ) {
    commit(
      ServiceCatalogueMutationTypes.SET_SERVICECATALOGUEITEM_TO_PREVIEW_MOD,
      payload
    );
  },

  [ServiceCatalogueActionTypes.SET_SERVICECATALOGUEITEM_PREVIEW_OPEN_STATE_ACTION](
    { commit }: AugmentedActionContext,
    payload: boolean
  ) {
    commit(
      ServiceCatalogueMutationTypes.SET_SERVICECATALOGUEITEM_PREVIEW_OPEN_STATE,
      payload
    );
  },
};
