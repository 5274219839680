import {
  ServiceCatalogueItem,
  ServiceCatalogueCategory,
  ServiceCataloguePriority,
  FieldDef,
  SortDef,
  // FilterOptionDef,
  ServiceCatalogueItemListFilters,
  FilterDef,
  // ServiceCatalogueImport,
  // ServiceCatalogueExport,
  // ServiceCatalogueState
} from '@/types/index'

const filters: FilterDef[] = ServiceCatalogueItemListFilters.map((f: FilterDef) => f);

export type ServiceCatalogueState = {

  serviceCatalogueItems: ServiceCatalogueItem[],
  isLoadingServiceCatalogueItems: boolean,
  isDeletingServiceCatalogueItems: boolean,
  deletedServiceCatalogueItemsData: null,
  isUpdatingServiceCatalogueItems: boolean,
  updatedServiceCatalogueItemsData: null,

  page: number,
  pageSize: number,
  total: number,
  search: string | null,
  filters: FilterDef[],
  availableFields: FieldDef[],
  sorts: SortDef[],

  serviceCatalogueItem: ServiceCatalogueItem | null,
  serviceCatalogueItemError: string | null,
  serviceCatalogueItemErrorValidation: any,
  isLoadingServiceCatalogueItem: boolean,
  isServiceCatalogueItemLoaded: boolean,
  isCreatingServiceCatalogueItem: boolean,
  isServiceCatalogueItemCreated: boolean,
  createdServiceCatalogueItemData: null,
  isUpdatingServiceCatalogueItem: boolean,
  isServiceCatalogueItemUpdated: boolean,
  updatedServiceCatalogueItemData: null,
  isDeletingServiceCatalogueItem: boolean,
  deletedServiceCatalogueItemSucceded: boolean,
  deletedServiceCatalogueItemData: null,

  serviceCatalogueItemToPreviewMod: string | null,
  serviceCatalogueItemPreviewOpenState: boolean,

  serviceCatalogueCategories: ServiceCatalogueCategory[],
  isLoadingServiceCatalogueCategories: boolean,
  isServiceCatalogueCategoriesLoaded: boolean,
  isSavingServiceCatalogueCategories: boolean,

  isLoadingServiceCatalogueCategory: boolean,
  isServiceCatalogueCategoryLoaded: boolean,
  isSavingServiceCatalogueCategory: boolean,

  isServiceCatalogueCategorySaved: boolean,
  serviceCatalogueCategorySaveError: string | null,

  isDeletingServiceCatalogueCategory: boolean,

  // categoryDeleted: boolean,
  // categoryDeleteError: string | null,

  serviceCataloguePriorities: ServiceCataloguePriority[],
  isLoadingServiceCataloguePriorities: boolean,
  isServiceCataloguePrioritiesLoaded: boolean,
  isSavingServiceCataloguePriorities: boolean,

  isLoadingServiceCataloguePriority: boolean,
  isServiceCataloguePriorityLoaded: boolean,
  isSavingServiceCataloguePriority: boolean,

  isServiceCataloguePrioritySaved: boolean,
  serviceCataloguePrioritySaveError: string | null,

  isDeletingServiceCataloguePriority: boolean,

  // priorityDeleted: boolean,
  // priorityDeleteError: string | null,

  // fields: ServiceCatalogueItemField[],
  // isLoadingFields: boolean,
  // isFieldsLoaded: boolean,
  // isSavingFields: boolean,
  // isLoadingField: boolean,
  // isFieldLoaded: boolean,
  // isSavingField: boolean,
  // isDeletingField: boolean,

  // fieldsPage: number,
  // fieldsPageSize: number,
  // fieldsTotal: number,

  // field: ServiceCatalogueField | null,
  // fieldError: String | null,
  // fieldErrorValidation: any,
  // isLoadingField: boolean,
  // isCreatingField: boolean,
  // createdFieldData: null,
  // isUpdatingField: boolean,
  // updatedFieldData: null,
  // isDeletingField: boolean,
  // deletedFieldSucceeded: boolean,
  // deletedFieldData: null,

  // imports: ServiceCatalogueImport[],
  // isLoadingImports: boolean,
  // isImportsLoaded: boolean,

  // import: ServiceCatalogueImport | null,
  // isLoadingImport: boolean,
  // isImportLoaded: boolean,

  // manufacturers: ServiceCatalogueManufacturer[],
  // isSavingManufacturers: boolean,
  // isManufacturersSaved: boolean,
  // isLoadingManufacturers: boolean,
  // isManufacturersLoaded: boolean,

  // models: ServiceCatalogueModel[],
  // isSavingModels: boolean,
  // isModelsSaved: boolean,
  // isLoadingModels: boolean,
  // isModelsLoaded: boolean,

  // lifeCycleStages: FilterOptionDef[],
  // isLoadingLifeCycleStages: boolean,
  // isLifeCycleStagesLoaded: boolean,

  // chartServiceCatalogueCategories: ChartsPieChart | null,
  // isLoadingChartServiceCatalogueCategories: boolean,
  // isChartServiceCatalogueCategoriesLoaded: boolean,

  // assetImport: ServiceCatalogueImport | null,
  // assetImportIsImporting: Boolean,
  // assetImportDone: Boolean,
  // assetImportResults: Object | null,

  // assetExportGenerating: Boolean,
  // assetExportGenerationDone: Boolean,
  // assetExportResults: Object | null,
  
  // assetExportsLoading: Boolean,
  // assetExportsLoaded: Boolean,
  // assetExports: ServiceCatalogueExport[]
};

export const state: ServiceCatalogueState = {

  serviceCatalogueItems: [],

  page: 1,
  pageSize: 10,
  total: 0,
  search: null,
  filters: filters,
  availableFields: [],
  sorts: [],

  isLoadingServiceCatalogueItems: false,
  isDeletingServiceCatalogueItems: false,
  deletedServiceCatalogueItemsData: null,
  isUpdatingServiceCatalogueItems: false,
  updatedServiceCatalogueItemsData: null,

  serviceCatalogueItem: null,
  serviceCatalogueItemError: null,
  serviceCatalogueItemErrorValidation: null,
  isLoadingServiceCatalogueItem: false,
  isServiceCatalogueItemLoaded: false,
  isCreatingServiceCatalogueItem: false,
  isServiceCatalogueItemCreated: false,
  createdServiceCatalogueItemData: null,
  isUpdatingServiceCatalogueItem: false,
  isServiceCatalogueItemUpdated: false,
  updatedServiceCatalogueItemData: null,
  isDeletingServiceCatalogueItem: false,

  deletedServiceCatalogueItemSucceded: false,
  deletedServiceCatalogueItemData: null,

  serviceCatalogueItemToPreviewMod: null,
  serviceCatalogueItemPreviewOpenState: false,

  serviceCatalogueCategories: [],
  isLoadingServiceCatalogueCategories: false,
  isServiceCatalogueCategoriesLoaded: false,
  isSavingServiceCatalogueCategories: false,

  isLoadingServiceCatalogueCategory: false,
  isServiceCatalogueCategoryLoaded: false,
  isSavingServiceCatalogueCategory: false,

  isServiceCatalogueCategorySaved: false,
  serviceCatalogueCategorySaveError: null,

  isDeletingServiceCatalogueCategory: false,

  serviceCataloguePriorities: [],
  isLoadingServiceCataloguePriorities: false,
  isServiceCataloguePrioritiesLoaded: false,
  isSavingServiceCataloguePriorities: false,

  isLoadingServiceCataloguePriority: false,
  isServiceCataloguePriorityLoaded: false,
  isSavingServiceCataloguePriority: false,

  isServiceCataloguePrioritySaved: false,
  serviceCataloguePrioritySaveError: null,

  isDeletingServiceCataloguePriority: false,

};

const _state = JSON.parse(JSON.stringify(state));
export const defaultState = (): ServiceCatalogueState => {
  {
    return JSON.parse(JSON.stringify(_state))
  }
};