import { GetterTree } from 'vuex';
import { RootState } from '@/store';

import {
    Staffing,
    FilterDef,
    FilterOptionDef,
    FieldDef,
    SortDef,
    StaffingChecklist
} from '@/types/index';
import { StaffingState as State } from './state';

export type StaffingGetters = {
    StateStaffings(state: State): Staffing[];
    StateIsLoadingStaffings(state: State): boolean;
    StateIsUpdatingStaffings(state: State): boolean;
    StateUpdatedStaffingsData(state: State): any;
    StateIsDeletingStaffings(state: State): boolean;
    StateDeletedStaffingsData(state: State): any;

    StatePage(state: State): number;
    StatePageSize(state: State): number;
    StateChecklistPage(state: State): number;
    StateChecklistPageSize(state: State): number;
    StateTotal(state: State): number;
    StateSearch(state: State): string | null;
    StateFilter(state: State): FilterDef[];
    StateAvailableFields(state: State): FieldDef[];
    StateSorts(state: State): SortDef[];

    StateStatuses(state: State): FilterOptionDef[];
    StateIsLoadingStatuses(state: State): boolean;
    StateStatusesLoaded(state: State): boolean;

    StateStaffingsKanban(state: State): Map<string, Staffing[]>;
    StateIsLoadingStaffingsKanban(state: State): Map<string, boolean>;
    StateIsUpdatingStaffingsKanban(state: State): Map<string, boolean>;
    StateIsDeletingStaffingsKanban(state: State): Map<string, boolean>;

    StateStaffingChecklist(state: State): StaffingChecklist[];
    StateIsLoadingStaffingChecklist(state: State): boolean;
    StateIsUpdatingStaffingChecklist(state: State): boolean;
    StateIsDeletingStaffingChecklist(state: State): boolean;
    StateSearchChecklist(state: State): string | null;
    StateSortsChecklist(state: State): SortDef[];

    StatePageKanban(state: State): Map<string, number>;
    StatePageSizeKanban(state: State): Map<string, number>;
    StateTotalKanban(state: State): Map<string, number>;
    StateSearchKanban(state: State): Map<string, string | null>;
    StateFilterKanban(state: State): Map<string, FilterDef[]>;
    StateAvailableFieldsKanban(state: State): Map<string, FieldDef[]>;
    StateSortsKanban(state: State): Map<string, SortDef[]>;

    StateStaffing(state: State): Staffing | null;
    StateStaffingError(state: State): String | null,
    StateStaffingChecklistError(state: State): String | null,
    StateStaffingErrorValidation(state: State): any,
    StateIsLoadingStaffing(state: State): boolean;
    StateIsStaffingLoaded(state: State): boolean;
    StateIsCreatingStaffing(state: State): boolean;
    StateIsUpdatingStaffing(state: State): boolean;
    StateIsStaffingUpdated(state: State): boolean;
    StateIsStaffingCreated(state: State): boolean;
    StateIsDeletingStaffing(state: State): boolean;

    StateStaffingToPreviewMod(state: State): string | null;
    StateStaffingPreviewOpenState(state: State): boolean;

    StateCreatedData(state: State): any;
    StateUpdatedData(state: State): any;
    StateIsDeleting(state: State): boolean;
    StateDeletedStaffingSucceeded(state: State): boolean;
    StateDeletedData(state: State): any;

  };

export const getters: GetterTree<State, RootState> & StaffingGetters = {
    StateStaffings: (state: State) => state.staffings,
    StateIsLoadingStaffings: (state: State) => state.isLoadingStaffings,
    StateIsUpdatingStaffings: (state: State) => state.isUpdatingStaffings,
    StateUpdatedStaffingsData: (state: State) => state.updatedStaffingsData,
    StateIsDeletingStaffings: (state: State) => state.isDeletingStaffings,
    StateDeletedStaffingsData: (state: State) => state.deletedStaffingsData,

    StatePage: (state: State) => state.page,
    StatePageSize: (state: State) => state.pageSize,
    StateChecklistPage: (state: State) => state.checklistPage,
    StateChecklistPageSize: (state: State) => state.checklistPageSize,
    StateTotal: (state: State) => state.total,
    StateSearch: (state: State) => state.search,
    StateFilter: (state: State) => state.filters,
    StateAvailableFields: (state: State) => state.availableFields,
    StateSorts: (state: State) => state.sorts,

    StateStatuses: (state: State) => state.statuses,
    StateIsLoadingStatuses: (state: State) => state.isLoadingStatuses,
    StateStatusesLoaded: (state: State) => state.isStatusesLoaded,

    StateStaffingsKanban: (state: State) => state.staffingsKanban,
    StateIsLoadingStaffingsKanban: (state: State) => state.isLoadingStaffingsKanban,
    StateIsUpdatingStaffingsKanban: (state: State) => state.isUpdatingStaffingsKanban,
    StateIsDeletingStaffingsKanban: (state: State) => state.isDeletingStaffingsKanban,

    StateStaffingChecklist: (state: State) => state.staffingChecklist,
    StateIsLoadingStaffingChecklist: (state: State) => state.isLoadingStaffingChecklist,
    StateIsUpdatingStaffingChecklist: (state: State) => state.isUpdatingStaffingChecklist,
    StateIsDeletingStaffingChecklist: (state: State) => state.isDeletingStaffingChecklist,
    StateSearchChecklist: (state: State) => state.searchChecklist,
    StateSortsChecklist: (state: State) => state.sortsChecklist,

    StatePageKanban: (state: State) => state.pageKanban,
    StatePageSizeKanban: (state: State) => state.pageSizeKanban,
    StateTotalKanban: (state: State) => state.totalKanban,
    StateSearchKanban: (state: State) => state.searchKanban,
    StateFilterKanban: (state: State) => state.filtersKanban,
    StateAvailableFieldsKanban: (state: State) => state.availableFieldsKanban,
    StateSortsKanban: (state: State) => state.sortsKanban,

    StateStaffing: (state: State) => state.staffing,
    StateStaffingError: (state: State) => state.staffingError,
    StateStaffingChecklistError: (state: State) => state.staffingChecklistError,
    StateStaffingErrorValidation: (state: State) => state.staffingErrorValidation,
    StateIsLoadingStaffing: (state: State) => state.isLoadingStaffing,
    StateIsStaffingLoaded: (state: State) => state.isStaffingLoaded,
    StateIsCreatingStaffing: (state: State) => state.isCreatingStaffing,
    StateIsUpdatingStaffing: (state: State) => state.isUpdatingStaffing,
    StateIsStaffingUpdated: (state: State) => state.isStaffingUpdated,
    StateIsStaffingCreated: (state: State) => state.isStaffingCreated,
    StateIsDeletingStaffing: (state: State) => state.isDeletingStaffing,

    StateStaffingToPreviewMod: (state: State) => state.staffingToPreviewMod,
    StateStaffingPreviewOpenState: (state: State) => state.staffingPreviewOpenState,

    StateCreatedData: (state: State) => state.createdStaffingData,
    StateUpdatedData: (state: State) => state.updatedStaffingData,
    StateIsDeleting: (state: State) => state.isDeletingStaffing,

    StateDeletedStaffingSucceeded: (state: State) => state.deletedStaffingSucceeded,
    StateDeletedData: (state: State) => state.deletedStaffingData
};
