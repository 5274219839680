// TODO: Remove no-shadow eslint disable after fixed: https://github.com/typescript-eslint/typescript-eslint/issues/2484
// eslint-disable-next-line no-shadow

export enum ServiceCatalogueMutationTypes {

  RESET = "reset",

  SET_SERVICECATALOGUEITEMS = 'setServiceCatalogueItems',
  SET_IS_LOADING_SERVICECATALOGUEITEMS = 'setIsLoadingServiceCatalogueItems',
  SET_DELETED_SERVICECATALOGUEITEMS_DATA = 'setDeletedServiceCatalogueItemsData',
  SET_IS_DELETING_SERVICECATALOGUEITEMS = 'setIsDeletingServiceCatalogueItems',
  SET_IS_UPDATING_SERVICECATALOGUEITEMS = 'setIsUpdatingServiceCatalogueItems',
  SET_UPDATED_SERVICECATALOGUEITEMS_DATA = 'setUpdatedServiceCatalogueItemsData',

  SET_PAGE = 'setPage',
  SET_PAGESIZE = 'setPageSize',
  SET_TOTAL = 'setTotal',
  SET_SEARCH = 'setSearch',
  SET_FILTER = 'setFilter',
  SET_AVAILABLEFIELDS = 'setAvailableFields',
  SET_SORTS = 'setSorts',

  SET_SERVICECATALOGUEITEM = 'setServiceCatalogueItem',
  SET_SERVICECATALOGUEITEM_ERROR = 'setServiceCatalogueItemError',
  SET_SERVICECATALOGUEITEM_ERROR_VALIDATION = 'setServiceCatalogueItemErrorValidation',
  SET_IS_LOADING_SERVICECATALOGUEITEM = 'setIsLoadingServiceCatalogueItem',
  SET_IS_SERVICECATALOGUEITEM_LOADED = 'setIsServiceCatalogueItemLoaded',
  SET_IS_CREATING_SERVICECATALOGUEITEM = 'setIsCreatingServiceCatalogueItem',
  SET_IS_SERVICECATALOGUEITEM_CREATED = 'setIsServiceCatalogueItemCreated',
  SET_CREATED_SERVICECATALOGUEITEM_DATA = 'setCreatedServiceCatalogueItemData',
  SET_IS_UPDATING_SERVICECATALOGUEITEM = 'setIsUpdatingServiceCatalogueItem',
  SET_IS_SERVICECATALOGUEITEM_UPDATED = 'setIsServiceCatalogueItemUpdated',
  SET_UPDATED_SERVICECATALOGUEITEM_DATA = 'setUpdatedServiceCatalogueItemData',
  SET_IS_DELETING_SERVICECATALOGUEITEM = 'setIsDeletingServiceCatalogueItem',
  
  SET_DELETED_SERVICECATALOGUEITEM_SUCCEEDED = 'setDeletedServiceCatalogueSucceeded',
  SET_DELETED_SERVICECATALOGUEITEM_DATA = 'setDeletedServiceCatalogueData',

  SET_SERVICECATALOGUEITEM_TO_PREVIEW_MOD = 'setServiceCatalogueToPreviewMod',
  SET_SERVICECATALOGUEITEM_PREVIEW_OPEN_STATE = 'setServiceCataloguePreviewOpenState',

  SET_FIELD = 'setField',
  SET_FIELD_ERROR = 'setFieldError',
  SET_FIELD_ERROR_VALIDATION = 'setFieldErrorValidation',
  SET_IS_LOADING_FIELD = 'setIsLoadingField',
  SET_IS_DELETING_FIELD = 'setIsDeletingField',
  SET_DELETED_FIELD_SUCCEEDED = 'setDeletedFieldSucceeded',
  SET_DELETED_FIELD_DATA = 'setDeletedFieldData',

  SET_SERVICECATALOGUE_CATEGORIES = 'setCategories',
  SET_IS_LOADING_SERVICECATALOGUE_CATEGORIES = 'setIsLoadingCategories',
  SET_IS_SERVICECATALOGUE_CATEGORIES_LOADED = 'setIsCategoriesLoaded',
  SET_IS_SAVING_SERVICECATALOGUE_CATEGORIES = 'setIsSavingCategories',

  SET_IS_LOADING_SERVICECATALOGUE_CATEGORY = 'setIsLoadingCategory',
  SET_IS_SERVICECATALOGUE_CATEGORY_LOADED = 'setIsCategoryLoaded',
  SET_IS_SAVING_SERVICECATALOGUE_CATEGORY = 'setIsSavingCategory',

  SET_IS_SERVICECATALOGUE_CATEGORY_SAVED = 'setIsCategorySaved',
  SET_SERVICECATALOGUE_CATEGORY_SAVE_ERROR = 'setCategorySaveError',

  SET_IS_DELETING_SERVICECATALOGUE_CATEGORY = 'setIsDeletingCategory',

  SET_SERVICECATALOGUE_PRIORITIES = 'setPriorities',
  SET_IS_LOADING_SERVICECATALOGUE_PRIORITIES = 'setIsLoadingPriorities',
  SET_IS_SERVICECATALOGUE_PRIORITIES_LOADED = 'setIsPrioritiesLoaded',
  SET_IS_SAVING_SERVICECATALOGUE_PRIORITIES = 'setIsSavingPriorities',

  SET_IS_LOADING_SERVICECATALOGUE_PRIORITY = 'setIsLoadingPriority',
  SET_IS_SERVICECATALOGUE_PRIORITY_LOADED = 'setIsPriorityLoaded',
  SET_IS_SAVING_SERVICECATALOGUE_PRIORITY = 'setIsSavingPriority',

  SET_IS_SERVICECATALOGUE_PRIORITY_SAVED = 'setIsPrioritySaved',
  SET_SERVICECATALOGUE_PRIORITY_SAVE_ERROR = 'setPrioritySaveError',

  SET_IS_DELETING_SERVICECATALOGUE_PRIORITY = 'setIsDeletingPriority',

}
