const OPermissionKeys = {

  article:"article",
  // articleCategory:"articleCategory",
  viewArticle: "viewArticle",
  // viewArticleCategory:"viewArticleCategory",
  // assetDocument:"assetDocument",
  session:"session",
  // notification:"notification",
  group:"group",
  // ticketActivity:"ticketActivity",
  user:"user",
  // assetCategory:"assetCategory",
  // manufacturer:"manufacturer",
  asset:"asset",
  // model:"model",
  permission:"permission",
  role:"role",
  organisation: "organisation",
  ticket: "ticket",
  staffing: "staffing",

  ticketMine: "ticketMine",
  staffingMine: "staffingMine",
  assetMine: "assetMine",
  profileMine: "profileMine",

  serviceDesk: "serviceDesk",
  billing: "billing",

  dashboard: "dashboard",



  // UserProfile: "UserProfile",
  // ServiceDesk: "ServiceDesk",
  // IncidentManagementTicket: "IncidentManagementTicket",
  // ServiceTicket: "ServiceTicket",
  // ViewOwnTickets: "ViewOwnTickets",
  // ViewTeamTickets: "ViewTeamTickets",
  // ViewOrganisationTickets: "ViewOrganisationTickets",
  // Dashboard: "Dashboard",
  // Assets: "Assets",
  // ViewOwnAssets: "ViewOwnAssets",
  // ViewOwnAssignedTickets: "ViewOwnAssignedTickets",
  // ViewTeamAssignedTickets: "ViewTeamAssignedTickets",
  // ViewOrganisationAssignedTickets: "ViewOrganisationAssignedTickets",
  // AssignUnassignTicketToAgents: "AssignUnassignTicketToAgents",
  // AcceptRejectAssignedTickets: "AcceptRejectAssignedTickets",
  // OrganisationProfile: "OrganisationProfile",
  // Settings: "Settings",
  // UsersAndGroups: "UsersAndGroups",
  // ActivityLog: "ActivityLog",
  // Billing: "Billing",
  // ExportImport: "ExportImport",
  // SystemView: "SystemView",
  // SystemViewEdit: "SystemViewEdit",
  UseChatBot: "UseChatBot",
  // ManageChatBot: "ManageChatBot",
  // UseKnowledgeBase: "UseKnowledgeBase",
  // ManageKnowledgeBase: "ManageKnowledgeBase",
} as const;
type PermissionKeys = typeof OPermissionKeys[keyof typeof OPermissionKeys];

class Permission {
  id: number | string | readonly string[];
  // type: string;
  // text: string;
  name: string;
  description: string;
  accessIsEditable: boolean;
  assignScope: string[];

  constructor(
    id: number | string | readonly string[],
    name: string,
    description: string,
    accessIsEditable: boolean,
    assignScope: string[]
  ) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.accessIsEditable = accessIsEditable;
    this.assignScope = assignScope;
  }

  static fromObject(obj: any): Permission {
    return new Permission(
      obj.id,
      obj.name,
      obj.description,
      obj.accessIsEditable,
      obj.assignScope
    );
  }

  static getDefaultObject(): Permission {
    return Permission.fromObject({
      id: -1,
      name: "",
      description: "",
      accessIsEditable: true,
      assignScope: []
    });
  }
}

export { Permission, PermissionKeys, OPermissionKeys };
