
import { defineComponent, ref } from "vue";
import { PermissionKeys } from "@/types/index";
import { RouteRecordRaw } from "vue-router";
import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { XMarkIcon } from "@heroicons/vue/24/outline";
import { userRoles, hasPermission } from "@/composables/UserRole";

export default defineComponent({
  name: "NarrowSidebar",
  components: {
    XMarkIcon,
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
  },
  computed: {
    hasManagement(): boolean {
      return (
        this.managementDownMenus.length > 0 || this.managementMenus.length > 0
      );
    },
    managementDownMenus(): RouteRecordRaw[] {
      const { user, roles } = userRoles();
      if (!user || !roles) return [];
      return this.$router.options.routes.filter((r: RouteRecordRaw) => {
        let _hasPermission = false; // RolePermission | undefined;
        if (r.meta?.permission) {
          _hasPermission = hasPermission(
            r.meta?.permission as PermissionKeys[] | null
          );
        }
        return (
          _hasPermission &&
          r.meta?.type === "menu" &&
          r.meta?.menu === "managementDown"
        );
      });
    },
    managementMenus(): RouteRecordRaw[] {
      const { user, roles } = userRoles();
      if (!user || !roles) return [];
      return this.$router.options.routes.filter((r: RouteRecordRaw) => {
        let _hasPermission = false;// undefined as RolePermission | undefined;
        if (r.meta?.permission) {
          if(r.meta?.permission == 'childs'){
            const perms = [] as Array<PermissionKeys|null>;
              r?.children?.forEach((c:RouteRecordRaw) => {
                perms.push(c?.meta?.permission as PermissionKeys | null);
              });


              _hasPermission = perms.map(p => hasPermission(
                p as PermissionKeys[] | null
              )).reduce((q,p) => q || p)
            // _hasPermission = hasPermission(
            //   r.meta?.permission as PermissionKeys | null
            // );

          } else {
            _hasPermission = hasPermission(
              r.meta?.permission as PermissionKeys[] | null
            );
          }
        }
        return (
          _hasPermission &&
          r.meta?.type === "menu" &&
          r.meta?.menu === "management"
        );
      });
    },
  },
  setup() {
    const mobileMenuOpen = ref(false);
    return {
      mobileMenuOpen,
    };
  },
});
