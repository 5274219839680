// import { state } from './modules/auth/state';
// import VuexORM from '@vuex-orm/core'
import { InjectionKey } from "vue";
// import { createStore, useStore as baseUseStore, Store, createLogger } from 'vuex'
import {
  createStore,
  // useStore as baseUseStore,
  // Store,
  // createLogger,
  // Store as VuexStore,
  // CommitOptions,
  // DispatchOptions,
} from "vuex";
// import createPersistedState from "vuex-persistedstate"
// import VuexPersister from 'vuex-persister'
// import SecureLS from "secure-ls";
import {
  store as auth,
  AuthStore,
  State as AuthState,
} from "@/store/modules/auth";
import { store as nav, NavStore, State as NavState } from "@/store/modules/nav";
import {
  store as asset,
  AssetStore,
  State as AssetState,
} from "@/store/modules/asset";
import {
  store as user,
  UserStore,
  State as UserState,
} from "@/store/modules/user";
import {
  store as tag,
  TagStore,
  State as TagState,
} from "@/store/modules/tag";
// import { store as admin, AdminStore, State as AdminState } from '@/store/modules/admin';
import {
  store as ticket,
  TicketStore,
  State as TicketState,
} from "@/store/modules/ticket";
import {
  store as organisation,
  OrganisationStore,
  State as OrganisationState,
} from "@/store/modules/organisation";
import {
  store as app,
  AppStore,
  State as AppState,
} from "@/store/modules/app";
// import { store as ticket, TicketStore, State as TicketState } from '@/store/modules/ticket';
import {
  store as knowledge,
  KnowledgeStore,
  State as KnowledgeState,
} from "@/store/modules/knowledge";
import {
  store as chatBot,
  ChatBotStore
} from "@/store/modules/chatBot";
import { ChatBotState } from '@/store/modules/chatBot/state';
import {
  store as notification,
  NotificationStore,
  State as NotificationState,
} from "@/store/modules/notification";
import {
  store as payment,
  PaymentStore,
  State as PaymentState,
} from "@/store/modules/payment";
// const normal_ls = new SecureLS({
//   encodingType: '',
//   isCompression: false
// });

// const secure_ls = new SecureLS({
//   encodingType: 'aes',
//   isCompression: true,
//   encryptionSecret: 'v&Br6YKf2l&#4*j$v2CKm6p!Wd*a9gA3'
// });
import {
  store as serviceCatalogue,
  ServiceCatalogueStore,
  State as ServiceCatalogueState,
} from "@/store/modules/serviceCatalogue";
import {
  store as staffing,
  StaffingStore,
  State as StaffingState,
} from "@/store/modules/staffing";

export type RootState = {
  auth: AuthState;
  nav: NavState;
  asset: AssetState;
  user: UserState;
  // admin: AdminState;
  ticket: TicketState;
  organisation: OrganisationState;
  app: AppState;
  knowledge: KnowledgeState;
  chatBot: ChatBotState;
  notification: NotificationState;
  tag: TagState;
  payment: PaymentState;
  serviceCatalogue: ServiceCatalogueState;
  staffing: StaffingState;
};

// export type Store = AuthStore<Pick<RootState, 'auth'>>
//   & NavStore<Pick<RootState, 'nav'>>
//   & AssetStore<Pick<RootState, 'asset'>>
//   & TicketStore<Pick<RootState, 'ticket'>>;

export type Store = AuthStore<Pick<RootState, "auth">> &
  NavStore<Pick<RootState, "nav">> &
  AssetStore<Pick<RootState, "asset">> &
  UserStore<Pick<RootState, "user">> &
  // & AdminStore<Pick<RootState, 'admin'>>
  TicketStore<Pick<RootState, "ticket">> &
  ChatBotStore<Pick<RootState, "chatBot">> &
  OrganisationStore<Pick<RootState, "organisation">> &
  AppStore<Pick<RootState, "app">> &
  KnowledgeStore<Pick<RootState, "knowledge">> &
  NotificationStore<Pick<RootState, "notification">> &
  TagStore<Pick<RootState, "tag">> &
  PaymentStore<Pick<RootState, "payment">>&
  ServiceCatalogueStore<Pick<RootState, "serviceCatalogue">>&
  StaffingStore<Pick<RootState, "staffing">>;

const debug = process.env.NODE_ENV !== "production";
// const plugins = debug ? [createLogger({})] : [];

// const SecureLocalStorage = new SecureLS({ encodingType: 'aes' })

// const vuexPersister = new VuexPersister<RootState>({
//   key: 'zebrit',
//   overwrite: true
// });

export const store = createStore({
  plugins: [
    // vuexPersister.persist
    // createPersistedState({
    //   storage: {
    //     getItem: (key: string) => secure_ls.get(key),
    //     setItem: (key: string, value: any) => secure_ls.set(key, value),
    //     removeItem: (key: string) => secure_ls.remove(key)
    //   }
    // })
  ],
  modules: {
    auth,
    nav,
    asset,
    user,
    // admin,
    ticket,
    organisation,
    app,
    knowledge,
    chatBot,
    notification,
    tag,
    payment,
    serviceCatalogue,
    staffing
  },
  // strict: debug,
  strict: process.env.NODE_ENV !== 'production'
});

export const resetStore = () => {
  // debugger; // eslint-disable-line
  store.commit('app/reset')
  store.commit('asset/reset')
  store.commit('auth/reset')
  // store.commit('chatbot/reset')
  store.commit('knowledge/reset')
  store.commit('nav/reset')
  store.commit('notification/reset')
  store.commit('organisation/reset')
  store.commit('payment/reset')
  store.commit('tag/reset')
  store.commit('ticket/reset')
  store.commit('ServiceCatalogue/reset')
  store.commit('staffing/reset')
  store.commit('user/reset')

  // adding reload to make all this work
  window.location.reload();

}

// define injection key
export const key: InjectionKey<Store> = Symbol();

export function useStore(): Store {
  return store as Store;
}
