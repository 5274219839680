export enum StaffingActionTypes {
    GET_STAFFINGS_ACTION = "GetStaffings",
    GET_MY_STAFFINGS_ACTION = "GetMyStaffings",
    // GET_STAFFINGS_CHARTS_ACTION = "GetStaffingsCharts",
    GET_STAFFING_ACTION = "GetStaffing",
    SET_STAFFING_ACTION = "SetStaffing",
    SET_STAFFINGS_ACTION = "SetStaffings",
    SAVE_STAFFING_ACTION = "SaveStaffing",
    SAVE_MY_STAFFING_ACTION = "SaveMyStaffing",
    CHANGE_STAFFING_STATUS_ORDER_ACTION = "ChangeStaffingStatusOrder",
    GET_CATEGORIES_ACTION = "GetCategories",
    GET_MANUFACTURERS_ACTION = "GetManufacturers",
    DELETE_STAFFING_ACTION = "DeleteStaffing",

    SET_STAFFING_TO_PREVIEW_MOD_ACTION = "SetStaffingToPreviewModAction",
    SET_STAFFING_PREVIEW_OPEN_STATE = "SetStaffingPreviewOpenStateAction",

    GET_STAFFING_CHECKLIST_ACTION = "GetStaffingChecklist",
    SET_STAFFING_CHECKLIST_ACTION = "SetStaffingChecklist",
    SAVE_STAFFING_CHECKLIST_ACTION = "SaveStaffingChecklist",
    DELETE_STAFFING_CHECKLIST_ACTION = "DeleteStaffingChecklist",

    GET_STATUSES_ACTION = "GetStatuses",

    SET_PAGE = "SetPage",
    SET_PAGESIZE = "SetPageSize",
    SET_CHECKLIST_PAGE = "SetChecklistPage",
    SET_CHECKLIST_PAGESIZE = "SetChecklistPageSize",
    SET_TOTAL = "SetTotal",
    SET_SEARCH = "SetSearch",
    SET_SEARCH_CHECKLIST = "SetSearchChecklist",
    SET_FILTER = "SetFilter",
    SET_AVAILABLEFIELDS = "SetAvailableFields",
    SET_SORTS = "SetSorts",
    SET_SORTS_CHECKLIST = "SetSortsChecklist",

    SET_MY_PAGE = "SetMyPage",
    SET_MY_PAGESIZE = "SetMyPageSize",
    SET_MY_TOTAL = "SetMyTotal",
    SET_MY_SEARCH = "SetMySearch",
    SET_MY_FILTER = "SetMyFilter",
    SET_MY_AVAILABLEFIELDS = "SetMyAvailableFields",
    SET_MY_SORTS = "SetMySorts",

    GET_STAFFINGS_KANBAN_ACTION = "GetStaffingsKanban",

    SET_PAGE_KANBAN = "SetPageKanban",
    SET_PAGESIZE_KANBAN = "SetPageSizeKanban",
    SET_TOTAL_KANBAN = "SetTotalKanban",
    SET_SEARCH_KANBAN = "SetSearchKanban",
    SET_FILTER_KANBAN = "SetFilterKanban",
    SET_AVAILABLEFIELDS_KANBAN = "SetAvailableFieldsKanban",
    SET_SORTS_KANBAN = "SetSortsKanban",

    GET_CHART_TYPES = "GetChartTypes",
    GET_CHART_SEVERITIES = "GetChartSeverities",
    GET_CHART_STATUSES = "GetChartStatuses",
    GET_CHART_MONTHLY = "GetChartMonthly"
  }
