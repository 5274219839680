/* eslint-disable no-unused-vars */
import { FieldDef, OFieldType, OActionType, FieldAction } from "./FieldDef";
import { FilterDef, OFilterType, OOptionsTypeStaffing } from "./FilterDef";
import { reactive } from "vue";
import dayjs from "dayjs";
import { User } from "./User";
import { ServiceCatalogueItem } from "./ServiceCatalogueItem";
import { Organisation } from "./Organisation";

const OStaffingType = {
  onBoarding: "onBoarding",
  offBoarding: "offBoarding",
} as const;
type StaffingType = keyof typeof OStaffingType;

const OStaffingChecklistStatus = {
  // not_started: "Not started",
  // in_progress: "In Progress",
  done: "Done",
  notDone: "Not Done",
};
type StaffingChecklistStatus = keyof typeof OStaffingChecklistStatus;

interface IStaffingTypeTransport {
  id: Number | string | readonly string[];
  name: String;
  key: StaffingType;
}

class Staffing {
  _id: number | string | readonly string[];
  user: User | number | string | readonly string[] | null;
  type: string;
  status: number | string | readonly string[] | null;
  statusName: string | null;
  statusOrder: number;
  checklist: StaffingChecklist[];
  organisation: Organisation | number | string | readonly string[] | null;
  createdAt: string | null;

  constructor(
    _id: number | string | readonly string[],
    user: number | string | readonly string[] | null,
    type: string,
    status: number | string | readonly string[] | null,
    statusName: string | null,
    statusOrder: number,
    checklist: StaffingChecklist[],
    organisation: Organisation | number | string | readonly string[] | null,
    createdAt: string | null
  ) {
    this._id = _id;
    this.user = user;
    this.type = type;
    this.status = status;
    this.statusName = statusName;
    this.statusOrder = statusOrder;
    this.checklist = checklist;
    this.organisation = organisation;
    this.createdAt = createdAt;
  }

  static fromObject(obj: any): Staffing {
    return new Staffing(
      obj._id,
      obj.user,
      obj.type,
      obj.status,
      obj.statusName,
      obj.statusOrder,
      obj.checklist,
      obj.organisation,
      obj.createdAt,
    );
  }

  static getDefaultObject(): Staffing {
    return Staffing.fromObject({
      user: null,
      type: 'onBoarding',
      status: null,
      statusName: null,
      statusOrder: 0,
      checklist: [],
      organisation: null,
      createdAt: null,
    });
  }
}

class StaffingStatus {
  _id: number | string | readonly string[];
  name: string | null;
  key: string | null;
  order: number;
  organisation: Organisation | number | string | readonly string[] | null;

  constructor(
    _id: number | string | readonly string[],
    name: string | null,
    key: string | null,
    order: number,
    organisation: Organisation | number | string | readonly string[] | null
  ) {
    this._id = _id;
    this.name = name;
    this.key = key;
    this.order = order;
    this.organisation = organisation;
  }

  static fromObject(obj: any): StaffingStatus {
    return new StaffingStatus(
        obj._id,
        obj.name,
        obj.key,
        obj.order,
        obj.organisation
    );
  }

  static getDefaultObject(): StaffingStatus {
    return StaffingStatus.fromObject({
      name: null,
      key: "",
      order: "",
      organisation: null,
    });
  }
}

class StaffingChecklist {
  _id: number | string | readonly string[];
  title: string | null;
  assignee: User | number | string | readonly string[] | null;
  status: StaffingChecklistStatus | null;
  staffing: number | string | readonly string[];
  serviceType: ServiceCatalogueItem | number | string | readonly string[] | null;

  constructor(
    _id: number | string | readonly string[],
    title: string | null,
    assignee: User | number | string | readonly string[] | null,
    status: StaffingChecklistStatus | null,
    staffing: number | string | readonly string[],
    serviceType: ServiceCatalogueItem | number | string | readonly string[] | null
  ) {
    this._id = _id;
    this.title = title;
    this.assignee = assignee;
    this.status = status;
    this.staffing = staffing;
    this.serviceType = serviceType;
  }

  static fromObject(obj: any): StaffingChecklist {
    return new StaffingChecklist(
      obj._id,
      obj.title,
      obj.assignee,
      obj.status,
      obj.staffing,
      obj.serviceType
    );
  }

  static getDefaultObject(): StaffingChecklist {
    return StaffingChecklist.fromObject({
      title: "",
      assignee: null,
      status: OStaffingChecklistStatus.notDone,
      staffing: null,
      serviceType: null,
    });
  }
}

class StaffingChecklistTemplate {
  _id: number | string | readonly string[];
  name: string;
  type: StaffingType | null;

  constructor(_id: number | string | readonly string[], name: string, type: null) {
    this._id = _id;
    this.name = name;
    this.type = type;
  }

  static fromObject(obj: any): StaffingChecklistTemplate {
    return new StaffingChecklistTemplate(obj._id, obj.name, obj.type);
  }

  static getDefaultObject(): StaffingChecklistTemplate {
    return StaffingChecklistTemplate.fromObject({
      name: "",
      type: null,
    });
  }
}

class StaffingChecklistTemplateItem {
  _id: number | string | readonly string[];
  title: string | null;
  assignee: number | string | readonly string[] | null;
  template: number | string | readonly string[];
  serviceType: number | string | readonly string[] | null;

  constructor(
    _id: number | string | readonly string[],
    title: string | null,
    assignee: number | string | readonly string[] | null,
    template: number | string | readonly string[],
    serviceType: number | string | readonly string[] | null
  ) {
    this._id = _id;
    this.title = title;
    this.assignee = assignee;
    this.template = template;
    this.serviceType = serviceType;
  }

  static fromObject(obj: any): StaffingChecklistTemplateItem {
    return new StaffingChecklistTemplateItem(
      obj._id,
      obj.title,
      obj.assignee,
      obj.template,
      obj.serviceType
    );
  }

  static getDefaultObject(): StaffingChecklistTemplateItem {
    return StaffingChecklistTemplateItem.fromObject({
      title: "",
      assignee: null,
      template: null,
      serviceType: null,
    });
  }
}

const StaffingListFields = [
  FieldDef.fromObject({
    id: "_id",
    title: "ID",
    available: true,
    show: false,
    type: OFieldType._id,
    sortable: false,
    searchable: false,
    actions: [
      FieldAction.fromObject({
        id: "details",
        text: "Details",
        icon: null,
        type: OActionType.view,
        color: "themeprimary",
      }),
    ],
    // [OActionType.view],
  }),

  FieldDef.fromObject({
    id: "subject",
    title: "Subject",
    available: true,
    show: true,
    type: OFieldType.String,
    sortable: true,
    searchable: true,
    actions: [
      FieldAction.fromObject({
        id: "details",
        text: "Details",
        icon: null,
        type: OActionType.view,
        color: "themeprimary",
      }),
    ],
    //[OActionType.edit],
  }),
  FieldDef.fromObject({
    id: "number",
    title: "Number",
    available: true,
    show: true,
    type: OFieldType.String,
    sortable: true,
    searchable: true,
    actions: [],
  }),
  FieldDef.fromObject({
    id: "type",
    title: "Type",
    available: true,
    show: true,
    type: OFieldType.Type,
    sortable: true,
    searchable: true,
    actions: [],
  }),
  FieldDef.fromObject({
    id: "severity",
    title: "Severity Level",
    available: true,
    show: true,
    type: OFieldType.Severity,
    sortable: true,
    searchable: true,
    actions: [],
  }),
  FieldDef.fromObject({
    id: "status",
    title: "Status",
    available: true,
    show: true,
    type: OFieldType.Status,
    sortable: true,
    searchable: true,
    actions: [],
  }),
  FieldDef.fromObject({
    id: "assignee",
    title: "Assigned To",
    available: true,
    show: true,
    type: OFieldType.User,
    sortable: true,
    searchable: false,
    actions: [],
  }),
  FieldDef.fromObject({
    id: "submittedBy",
    title: "Submitted By",
    available: true,
    show: true,
    type: OFieldType.User,
    sortable: true,
    searchable: false,
    actions: [],
  }),
  FieldDef.fromObject({
    id: "actions",
    title: "Actions",
    available: true,
    show: true,
    type: OFieldType.Actions,
    sortable: false,
    searchable: false,
    //actions: [OActionType.view, OActionType.edit],
    actions: [
      FieldAction.fromObject({
        id: "details",
        text: "Details",
        icon: null,
        type: OActionType.view,
        color: "themeprimary",
      }),
      // FieldAction.fromObject({
      //   id: "edit",
      //   text: "Edit",
      //   icon: null,
      //   type: OActionType.edit,
      //   color: "themeprimary",
      // }),
      FieldAction.fromObject({
        id: "delete",
        text: "Delete",
        icon: null,
        type: OActionType.delete,
        color: "red",
      }),
    ],
    //  [OActionType.edit, OActionType.delete],
  }),
];

const StaffingChecklistListFields = [
  FieldDef.fromObject({
    id: "status",
    title: "Check",
    available: true,
    show: true,
    type: OFieldType.RadioCheck,
    sortable: false,
    searchable: false,
    actions: [
      FieldAction.fromObject({
        id: "check",
        text: "Check",
        type: OActionType.check,
      }),
    ],
  }),
  FieldDef.fromObject({
    id: "_id",
    title: "ID",
    available: true,
    show: false,
    type: OFieldType._id,
    sortable: false,
    searchable: false,
    actions: [
      // FieldAction.fromObject({
      //   id: "details",
      //   text: "Details",
      //   icon: null,
      //   type: OActionType.view,
      //   color: "themeprimary",
      // }),
    ],
    // [OActionType.view],
  }),
  FieldDef.fromObject({
    id: "title",
    title: "Title",
    available: true,
    show: true,
    type: OFieldType.String,
    sortable: false,
    searchable: true,
    actions: [
      FieldAction.fromObject({
        id: "check",
        text: "Check",
        type: OActionType.check,
      }),
    ],
  }),
  FieldDef.fromObject({
    id: "assignee",
    title: "Assignee",
    available: true,
    show: true,
    type: OFieldType.Assignee,
    sortable: false,
    searchable: true,
    actions: [],
  }),
  FieldDef.fromObject({
    id: "serviceType",
    title: "Service Type",
    available: true,
    show: true,
    type: OFieldType.ServiceType,
    sortable: false,
    searchable: true,
    actions: [],
  }),
  FieldDef.fromObject({
    id: "actions",
    title: "Actions",
    available: true,
    show: true,
    type: OFieldType.Actions,
    sortable: false,
    searchable: false,
    //actions: [OActionType.view, OActionType.edit],
    actions: [
      FieldAction.fromObject({
        id: "edit",
        text: "Edit",
        icon: null,
        type: OActionType.edit,
        color: "themeprimary",
      }),
      FieldAction.fromObject({
        id: "delete",
        text: "Delete",
        icon: null,
        type: OActionType.delete,
        color: "red",
      }),
    ],
  }),
];

const StaffingListFilters = [
  FilterDef.fromObject({
    id: "number",
    queryParam: "number",
    title: "Number",
    type: OFilterType.StringSearch,
    optionsType: null,
    options: [],
    value: "",
  }),
  FilterDef.fromObject({
    id: "subject",
    queryParam: "subject",
    title: "Subject",
    type: OFilterType.StringSearch,
    optionsType: null,
    options: [],
    value: "",
  }),
  FilterDef.fromObject({
    id: "type",
    queryParam: "type",
    title: "Type",
    type: OFilterType.Enum,
    optionsType: OOptionsTypeStaffing.StaffingType,
    options: reactive([]),
    value: "",
  }),
  FilterDef.fromObject({
    id: "status",
    queryParam: "status",
    title: "Status",
    type: OFilterType.Enum,
    optionsType: OOptionsTypeStaffing.StaffingStatus,
    options: reactive([]),
    value: "",
  }),
  FilterDef.fromObject({
    id: "assignee",
    queryParam: "assignee",
    title: "Assigned To",
    type: OFilterType.User,
    optionsType: OOptionsTypeStaffing.Assignee,
    options: reactive([]),
    value: "",
  }),
];

export {
  Staffing,
  OStaffingType,
  StaffingType,
  OStaffingChecklistStatus,
  StaffingStatus,

  StaffingListFields,
  StaffingListFilters,

  StaffingChecklistListFields,

  IStaffingTypeTransport,
  StaffingChecklist,
};
