import { GetterTree } from 'vuex';
import { RootState } from '@/store';
import { AppState as State, ThemeModes } from './state';

export type AppGetters = {
  StateMode(state: State): ThemeModes,
  StateDirt(state: State): boolean,
  StateDirtyShowResolve(state: State): (vale?:any) => void,
  StateDirtyShowReject(state: State): (vale?:any) => void,
  StateDirtModal(state: State): boolean,
  StateApiVersion(state: State): string,
}
export const getters: GetterTree<State, RootState> & AppGetters = {
  StateMode: (state: State) => state.mode,
  StateDirt: (state: State) => state.dirt,
  StateDirtyShowResolve: (state: State) => state.dirtyShowResolve,
  StateDirtyShowReject: (state: State) => state.dirtyShowReject,
  StateDirtModal: (state: State) => state.dirtModal,
  StateApiVersion: (state: State) => state.apiVersion
};
