export enum StaffingMutationTypes {
  RESET = "reset",

  SET_STAFFINGS = "setStaffings",
  SET_IS_LOADING_STAFFINGS = "setIsLoadingStaffings",
  SET_DELETED_STAFFINGS_DATA = "setDeletedStaffingsData",
  SET_IS_DELETING_STAFFINGS = "setIsDeletingStaffings",
  SET_IS_UPDATING_STAFFINGS = "setIsUpdatingStaffings",
  SET_UPDATED_STAFFINGS_DATA = "setUpdatedStaffingsData",

  SET_PAGE = "setPage",
  SET_PAGESIZE = "setPageSize",
  SET_TOTAL = "setTotal",
  SET_SEARCH = "setSearch",
  SET_SEARCH_CHECKLIST = "setSearchChecklist",
  SET_FILTER = "setFilter",
  SET_AVAILABLEFIELDS = "setAvailableFields",
  SET_SORTS = "setSorts",
  SET_SORTS_CHECKLIST = "setSortsChecklist",

  SET_CHECKLIST_PAGE = "setPage",
  SET_CHECKLIST_PAGESIZE = "setPageSize",

  SET_STAFFINGS_KANBAN = "setStaffingsKanban",
  SET_IS_LOADING_STAFFINGS_KANBAN = "setIsLoadingStaffingsKanban",
  SET_IS_DELETING_STAFFINGS_KANBAN = "setIsDeletingStaffingsKanban",
  SET_IS_UPDATING_STAFFINGS_KANBAN = "setIsUpdatingStaffingsKanban",

  SET_STAFFING_CHECKLIST= "setStaffingChecklist",
  SET_IS_LOADING_STAFFING_CHECKLIST = "setIsLoadingStaffingChecklist",
  SET_IS_UPDATING_STAFFING_CHECKLIST = "setIsUpdatingStaffingChecklist",
  SET_IS_DELETING_STAFFING_CHECKLIST = "setIsDeletingStaffingChecklist",

  SET_PAGE_KANBAN = "setPageKanban",
  SET_PAGESIZE_KANBAN = "setPageSizeKanban",
  SET_TOTAL_KANBAN = "setTotalKanban",
  SET_SEARCH_KANBAN = "setSearchKanban",
  SET_FILTER_KANBAN = "setFilterKanban",
  SET_AVAILABLEFIELDS_KANBAN = "setAvailableFieldsKanban",
  SET_SORTS_KANBAN = "setSortsKanban",

  SET_STAFFING = "setStaffing",
  SET_STAFFING_ERROR = 'setStaffingError',
  SET_STAFFING_CHECKLIST_ERROR = 'setStaffingChecklistError',
  SET_STAFFING_ERROR_VALIDATION = 'setStaffingErrorValidation',
  SET_IS_LOADING_STAFFING = "setIsLoadingStaffing",
  SET_IS_STAFFING_LOADED = "setIsStaffingLoaded",
  SET_IS_CREATING_STAFFING = "setIsCreatingStaffing",
  SET_CREATED_STAFFING_DATA = "setCreatedStaffingData",
  SET_IS_UPDATING_STAFFING = "setIsUpdatingStaffing",
  SET_IS_STAFFING_UPDATED = "setIsStaffingUpdated",
  SET_IS_STAFFING_CREATED = "setIsStaffingCreated",
  SET_UPDATED_STAFFING_DATA = "setUpdatedStaffingData",
  SET_IS_DELETING_STAFFING = "setIsDeletingStaffing",

  SET_DELETED_STAFFING_SUCCEEDED = "setDeletedStaffingSucceeded",
  SET_DELETED_STAFFING_DATA = "setDeletedStaffingData",

  // SET_IS_UPDATING_STAFFING_STATUS_ORDER = ""

  SET_STAFFING_TO_PREVIEW_MOD = "setStaffingToPreviewMod",
  SET_STAFFING_PREVIEW_OPEN_STATE = "setStaffingPreviewOpenState",

  SET_STATUSES = "setStatuses",
  SET_IS_LOADING_STATUSES = "setIsLoadingStatuses",
  SET_IS_STATUSES_LOADED = "setIsStatusesLoaded",

}
